import AbstractFilter from './AbstractFilter';

export default class extends AbstractFilter {
    _createFormControlsChild() { // Implementation of abstract method
        const select = document.createElement('select');
        select.className = 'form-control';
        select.id = `field-${this._getDefinition().key}`;
        select.name = this.getFieldName();

        for (let {key, label} of this._getDefinition().possible_values) {
            select.appendChild(this._createOption(key, label));
        }

        if (typeof this._getInitialValue() === 'string') {
            select.value = this._getInitialValue();
        }

        return select;
    }

    _createOption(key, label) {
        const option = document.createElement('option');
        option.value = key;
        option.innerText = label;

        return option;
    }
}