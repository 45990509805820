export default class {
    constructor(keyOrPair, encodedValue = null) {
        this._key = '';
        this._encodedValue = '';

        if (typeof encodedValue === 'string') {
            this._initFromKeyAndValue(keyOrPair, encodedValue)
        } else {
            this._initFromPairString(keyOrPair);
        }
    }
    getKey() {
        return this._key;
    }
    getEncodedValue() {
        return this._encodedValue;
    }
    isFilter() {
        return this._key.indexOf('filter_') === 0; // todo get from script param, from php constants
    }
    joinPair() {
        return this._key + '=' + this._encodedValue;
    }
    clone() {
        return new this.constructor(this._key, this._encodedValue);
    }
    _initFromKeyAndValue(key, encodedValue) {
        this._key = key;
        this._encodedValue = encodedValue;
    }
    _initFromPairString(keyValuePair) {
        const [key, encodedValue] = keyValuePair.split('=');

        this._key = key;
        this._encodedValue = encodedValue;
    }
}