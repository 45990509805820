import mouseManager from 'modules/mouseManager';
import createInfoBubble from 'components/InfoBubble';

function objectHasValue(object) {
    return typeof object === 'object' && object !== null;
}

function getBubbleMarkupUrl(dataAttributeValue) {
    if (typeof dataAttributeValue === 'number') {
        const idproduct = dataAttributeValue;

        return `/products/${idproduct}/info-bubble`;
    }

    if (objectHasValue(dataAttributeValue)) {
        const {idproduct, idwarehouse} = dataAttributeValue;

        if (typeof idproduct === 'number') {
            let bubbleMarkupUrl = `/products/${idproduct}/info-bubble`;
            if (typeof idwarehouse === 'number') {
                bubbleMarkupUrl += '?idwarehouse=' + idwarehouse;
            }

            return bubbleMarkupUrl;
        }
    }

    return null;
}

export default function () {
    mouseManager.addMouseOverHandler(({target}) => {
        if (! target.hasAttribute('data-product-bubble') || target.hasAttribute('data-info-bubble-bound')) {
            return;
        }
        const bubbleMarkupUrl = getBubbleMarkupUrl(JSON.parse(target.dataset.productBubble));
        createInfoBubble(target, bubbleMarkupUrl, {autoTriggerMouseOverAfterBind: true});
    });
};
