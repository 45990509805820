export default (target, eventName) => {
    if (typeof window.Event !== 'function') {
        // IE<=11 fallback
        const event = document.createEvent('Event');
        event.initEvent('picqer:markup-driven-behavior-confirmed', true, true);
        target.dispatchEvent(event);
        return;
    }

    target.dispatchEvent(new Event(eventName));
};