import translate from 'modules/translate';
import {iconHtml} from "modules/icon";

export default class {
    constructor() {
        this._onHidden = null;
        this._background = null;
        this._filtersFormPopover = null;
    }
    onHidden(handler) {
        this._onHidden = handler;

        return this;
    }
    show(parentFiltersContainer, formElementPromise) {
        this._background = this._createBackground();

        this._filtersFormPopover = this._createFiltersFormPopover()
        parentFiltersContainer.appendChild(this._filtersFormPopover);

        formElementPromise.then(formElement => {
            this._removeElement(this._filtersFormPopover.querySelector('.big-loading-message'));
            this._filtersFormPopover.appendChild(formElement);
        }).catch(error => {
            alert(error.message);
            this._hide();
        });
    }

    _hide() {
        this._removeElement(this._background);
        this._removeElement(this._filtersFormPopover);

        if (typeof this._onHidden === 'function') {
            this._onHidden();
        }
    }
    _createFiltersFormPopover() {
        const filtersFormPopover = document.createElement('div');
        filtersFormPopover.className = 'filters-form-popover';
        filtersFormPopover.appendChild(this._createBigLoadingMessage());

        return filtersFormPopover;
    }
    _createBigLoadingMessage() {
        const bigLoadingMessage = document.createElement('div');
        bigLoadingMessage.className = 'big-loading-message';
        bigLoadingMessage.innerHTML = iconHtml('spinner') + translate('general', 'Loading') + '...';

        return bigLoadingMessage;
    }
    _removeElement(element) {
        element.parentElement.removeChild(element);
    }
    _createBackground() {
        const background = document.createElement('div');
        background.className = 'filters-background';
        document.body.appendChild(background);

        background.addEventListener('click', e => {
            if (e.target === e.currentTarget) {
                this._hide();
            }
        });

        return background;
    }
}
