import { unhandled } from "modules/ajax-utility";
import { get, post } from "modules/ajax-vanilla";
import translate from "modules/translate";
import Modal from "components/Modals/Modal";

const trans = (key, namespace = "support") => translate(namespace, key);

export default class SupportModal extends Modal {
    constructor(modalRoot) {
        super(modalRoot);

        this._formEventsAreBound = false;
    }

    open() {
        super.open();

        if (!this._formEventsAreBound) {
            this._bindSupportFormEvents();
            this._bindSupportFormToggle();

            this._setupSupportSearch();
        }
    }

    _bindSupportFormEvents() {
        this._formEventsAreBound = true;

        const submitMessageButton = document.getElementById("support-send-button");
        if (!(submitMessageButton instanceof window.HTMLElement)) {
            return;
        }

        submitMessageButton.addEventListener("click", () => {
            this._submitFeedback();
        });
    }

    _bindSupportFormToggle() {
        const supportModal = document.getElementById("support-wrapper");
        if (supportModal instanceof window.HTMLElement) {
            supportModal.addEventListener("click", (event) => {
                const showSupportFormButton = event.target.closest(".contact-support-button");
                if (showSupportFormButton !== null) {
                    event.preventDefault();
                    this._showSupportForm();
                }
            });
        }
    }

    _showSupportForm() {
        document.querySelector(".support-form").classList.remove("hidden");
        document.querySelector(".support-search").classList.add("hidden");
        document.querySelector(".picqer-modal-footer").classList.add("hidden");
    }

    _submitFeedback() {
        const submitMessageButton = document.getElementById("support-send-button");
        const isUrgentCheckbox = document.getElementById("support-urgent");
        const isUrgent = isUrgentCheckbox != null && isUrgentCheckbox.checked;

        // Empty previous errors
        document.getElementById("support-modal-errors").innerHTML = "";

        // Don't let users send empty messages, show error instead
        if (document.getElementById("support-question").value.length < 6) {
            const errorMessage = document.createElement("p");
            errorMessage.className = "alert alert-danger";
            errorMessage.innerText = trans("Please provide a message for our support team.");

            document.getElementById("support-modal-errors").prepend(errorMessage);

            return;
        }

        submitMessageButton.setAttribute("disabled", true);

        post("/api/v1/feedback", this._getMessagePostData())
            .then(() => {
                submitMessageButton.className += " btn-success";
                submitMessageButton.innerText =
                    isUrgent === true ? trans("Succesfully sent with urgency") : trans("Succesfully sent");
            })
            .catch(
                unhandled((errorMessage) => {
                    submitMessageButton.removeAttribute("disabled");

                    if (isUrgent) {
                        // eslint-disable-next-line no-alert
                        confirm(
                            trans("Something went wrong while sending with urgency. Would you like to retry?"),
                            trans("Yes"),
                            trans("No")
                        ).then((didConfirm) => {
                            if (didConfirm) {
                                this._submitFeedback();
                            }
                        });
                    } else {
                        // eslint-disable-next-line no-alert
                        alert(errorMessage);
                    }
                })
            );
    }

    _getMessagePostData() {
        const urgentCheckbox = document.getElementById("support-urgent");

        return {
            subject: document.getElementById("support-subject").value,
            question: document.getElementById("support-question").value,
            urgent: urgentCheckbox !== null && urgentCheckbox.checked,
            currenturl: window.location.href,
            locale: document.getElementById("support-locale").value,
        };
    }

    _setupSupportSearch() {
        const searchInput = document.getElementById("support-search-input");
        if (searchInput instanceof window.HTMLElement) {
            searchInput.addEventListener(
                "keyup",
                this._debounce(() => {
                    this._executeSupportSearch();
                }, 300)
            );
        }

        const supportKeywordsOnPageElement = document.getElementById("support-keywords");
        if (supportKeywordsOnPageElement instanceof window.HTMLElement) {
            this._executeSupportSearch(supportKeywordsOnPageElement.dataset.keywords);
        }
    }

    _executeSupportSearch(query) {
        get("/support-modal/search", {
            q: query ?? document.getElementById("support-search-input").value,
        })
            .then((response) => {
                document.getElementById("support-search-results").innerHTML = response;
            })
            .catch(
                unhandled((errorMessage) => {
                    document.getElementById("support-search-results").innerHTML = errorMessage;
                })
            );
    }

    _debounce(fn, duration) {
        let timer;
        return function () {
            clearTimeout(timer);
            timer = setTimeout(fn, duration);
        };
    }
}
