function logScriptParamsForDevelopment(bundleIdentifier, scriptParamsToLog) {
    if (! window.picqer.dev) {
        return;
    }

    console.groupCollapsed('DEV: ' + bundleIdentifier + ' params');
    console.log(JSON.stringify(scriptParamsToLog, undefined, 2));
    console.groupEnd();
}

export default (bundleIdentifier) => {
    const bundleScriptTag = document.getElementById('bundle-script-tag-' + bundleIdentifier.replace(/\//g, '-'));
    if (! (bundleScriptTag instanceof window.HTMLScriptElement)) {
        throw Error(`Bundle with identifier "${bundleIdentifier}" not found in document.`);
    }

    const paramsDataAttribute = bundleScriptTag.getAttribute('data-params');
    if (typeof paramsDataAttribute !== 'string' || paramsDataAttribute.length === 0) {

        return undefined;
    }

    const scriptParams = JSON.parse(paramsDataAttribute);
    logScriptParamsForDevelopment(bundleIdentifier, scriptParams);

    return scriptParams;
}