import Modal from "components/Modals/Modal";

const bindModalClose = (element, modal) => {
    if (! element.dataset.modalClose) {
        return;
    }

    const closeElement = element.querySelector(element.dataset.modalClose);

    if (closeElement) {
        closeElement.addEventListener('click', () => {
            modal.close();
        });
    }
};

const bindModalDefaultClose = (element, modal) => {
    const closeElement = element.querySelector('button.close-modal');

    if (closeElement) {
        closeElement.addEventListener('click', () => {
            modal.close();
        });
    }
};

const bindModalTrigger = (element, modal) => {
    if (! element.dataset.modalTrigger) {
        modal.open();
        return;
    }

    const triggerElement = document.querySelector(element.dataset.modalTrigger);
    element.style.display = 'none';

    if (! triggerElement) {
        return;
    }

    triggerElement.addEventListener('click', () => {
        element.style.display = 'block';
        modal.open();
    });
};

const liveBindSimpleModals = () => {
    document.querySelectorAll('[data-modal]').forEach(element => {
        const modal = new Modal(element);

        bindModalClose(element, modal);
        bindModalDefaultClose(element, modal);
        bindModalTrigger(element, modal);
    });
};

export default liveBindSimpleModals;