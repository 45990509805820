import { unhandled } from "modules/ajax-utility";
import { get } from "modules/ajax-vanilla";
import { isSelfOrDescendantOfSelector } from "modules/dom";
import translate from "modules/translate";
import difference from "lodash/difference";
import reject from "lodash/reject";
import { makeSelectableTag } from "./selectable-tag";
import debounce from "lodash/debounce";
import escapeRegExp from "lodash/escapeRegExp";

const KEY_DOWN = 40;
const KEY_ENTER = 13;
const KEY_ESCAPE = 27;
const KEY_TAB = 9;
const KEY_UP = 38;

const template = `
<div :class="'tags-input-container' + (editing ? ' tags-input-container-editing' : '')" @keydown.enter.stop>
    <div class="tags-input-selected-tags" @click.stop="editing = fetchedAllSelectedTags">
        <div v-for="tag in selectedTags" class="picqer-tag">
            <span class="picqer-tag-color" :style="'background-color:' + tag.color">&nbsp;</span>
            <span class="picqer-tag-title">{{ tag.title }}</span>
        </div>
        <span v-if="selectedTags.length === 0 && fetchedAllSelectedTags" class="tags-input-no-tags-selected">{{ trans('No tags yet') }}</span>
        <span v-if="fetchedAllSelectedTags" class="tags-input-edit-cue"><Icon name="pencil" /> {{ trans('Edit') }}</span>
    </div>
    <template v-if="editing">
        <div class="tags-input-editor">
            <div class="tags-input-filter-field-container">
                <input class="form-control" type="text" :placeholder="trans('Filter tags')" v-model="search" @keydown="onFilterKeyDown"/>
            </div>
            <div class="tags-input-selectable-tags">
                <template v-if="tagsInOrderDuringEdit && tagsInOrderDuringEdit.length">
                    <selectable-tag v-for="(tag, index) in tagsInOrderDuringEdit" :key="tag.idtag" :tag="tag" :is-selected="isTagSelected(tag)" :has-focus="index === focusedTagIndex" @toggle-idtag="onToggleTagThroughClick"></selectable-tag>
                </template>
                <template v-else>
                    <div class="tags-input-no-results">{{ trans('No matching tags') }}</div>
                </template>
            </div>
        </div>    
    </template>
</div>`;

export function makeTagsInput() {
  if (!window.Vue) {
    throw Error("Vue is not yet loaded");
  }

  require("components/_vue/icon");

  return window.Vue.extend({
    template,
    components: { SelectableTag: makeSelectableTag() },
    props: {
      selectedIdTags: { required: true, type: Array },
    },
    data() {
      return {
        availableTagsOrNull: null, // Data container that is null until loaded from server
        canSearchLocally: false, // Only true if API can return all tags in one call (100 per page)
        editing: false,
        fetchedAllSelectedTags: false,
        focusedTagIndex: null,
        search: "",
        tagsInOrderDuringEdit: null, // Array with the tags in convenient fixed order during editing (selected ones on top). Resets when exiting edit-mode.
      };
    },
    computed: {
      availableUnselectedTags() {
        return reject(this.availableTags, (availableTag) => this.selectedIdTags.includes(availableTag.idtag));
      },
      availableTags() {
        // Returns an array, even if tags are not yet loaded.
        return this.availableTagsOrNull || [];
      },
      selectedTags() {
        const result = [];
        this.availableTags.forEach((tag) => {
          if (this.selectedIdTags.includes(tag.idtag)) {
            result.push(tag);
          }
        });
        return result;
      },
    },
    methods: {
      // The API returns 100 results, meaning that a tag that is selected may not be included in the initial fetch result (tags/index). This method fetches the missing tag(s)
      // Can only be called after the initial API request that returns at most 100 tags.
      fetchPotentiallyMissingTags() {
        if (this.selectedTags.length < this.selectedIdTags.length) {
          // In this case, the API returned a subset of all tags, with at least one of the selected tags missing.
          const fetchedIdTags = this.selectedTags.map((selectedTag) => selectedTag.idtag);
          const missingIdTags = difference(this.selectedIdTags, fetchedIdTags);

          const missingTagFetches = missingIdTags.map((idtag) => {
            return get(`/api/v1/tags/${idtag}`).catch((error) => {
              // We catch the error and check the status code. If the status code is anything other than
              // 404, we rethrow the error. We do this because we can ignore 404 errors. This occurs
              // when a tag is deleted (deleted tags can still appear in a preset).
              if (error.status !== 404) {
                throw error;
              }
            });
          });

          Promise.all(missingTagFetches).then((missingTags) => {
            this.availableTagsOrNull = this.availableTagsOrNull.concat(missingTags.filter((tag) => tag !== undefined));
            this.fetchedAllSelectedTags = true;
          });
        } else {
          this.fetchedAllSelectedTags = true;
        }
      },
      focusAndSelectFilterInput() {
        this.$nextTick(() => {
          const inputElement = this.$el.querySelector(".tags-input-editor input[type=text]");
          inputElement.focus();
          inputElement.select();
        });
      },
      isTagSelected(tag) {
        return this.selectedIdTags.includes(tag.idtag);
      },
      onClickOutsideEditor(e) {
        if (!isSelfOrDescendantOfSelector(e.target, ".tags-input-editor")) {
          document.removeEventListener("click", this.onClickOutsideEditor);
          this.editing = false;
        }
      },
      onFilterKeyDown(e) {
        if (e.which === KEY_TAB || e.which === KEY_ESCAPE) {
          this.search = ""; // Not setting this here results in a scope error.
          this.editing = false;
        }
        if (this.focusedTagIndex !== null) {
          if (e.which === KEY_UP && this.focusedTagIndex > 0) {
            e.preventDefault();
            this.focusedTagIndex -= 1;
          }
          if (e.which === KEY_DOWN && this.focusedTagIndex < this.tagsInOrderDuringEdit.length - 1) {
            e.preventDefault();
            this.focusedTagIndex += 1;
          }
        }
        if (e.which === KEY_ENTER) {
          e.preventDefault();
          if (this.focusedTagIndex !== null) {
            this.$emit("toggle-idtag", this.tagsInOrderDuringEdit[this.focusedTagIndex].idtag);
            this.focusAndSelectFilterInput();
          }
        }
      },
      onToggleTagThroughClick(idtag) {
        // Event handler
        this.focusAndSelectFilterInput();
        this.$emit("toggle-idtag", idtag); // By default the event stops bubbling once handled. We need to continue propagation so re-fire the same event.
      },
      updateTagsInOrderBasedOnSearchResult(searchResultTags) {
        this.tagsInOrderDuringEdit = []
          .concat(searchResultTags.filter((tag) => this.selectedIdTags.includes(tag.idtag)))
          .concat(searchResultTags.filter((tag) => !this.selectedIdTags.includes(tag.idtag)));
      },
      searchAPI: debounce(function (callback) {
        get("/api/v1/tags", { search: this.search })
          .then((searchResultTags) => {
            // For each result, make sure it's part of the availableTags collection.
            const availableIdTags = this.availableTags.map((availableTag) => availableTag.idtag);
            searchResultTags.forEach((searchResultTag) => {
              if (!availableIdTags.includes(searchResultTag.idtag)) {
                this.availableTags.push(searchResultTag);
              }
            });
            callback(searchResultTags);
          })
          .catch(unhandled((errorMessage) => alert(errorMessage)));
      }, 500),
      searchLocally: debounce(function (callback) {
        const result = [];
        const regex = new RegExp(escapeRegExp(this.search), "i");
        this.availableTags.forEach((availableTag) => {
          if (regex.test(availableTag.title)) {
            result.push(availableTag);
          }
        });
        callback(result);
      }, 200),
      trans(key, namespace = "tags_editor") {
        return translate(namespace, key);
      },
    },
    watch: {
      editing() {
        if (this.editing) {
          document.addEventListener("click", this.onClickOutsideEditor);
          this.tagsInOrderDuringEdit = this.selectedTags.concat(this.availableUnselectedTags);
          this.focusAndSelectFilterInput();
        } else {
          this.focusedTagIndex = null;
          this.tagsInOrderDuringEdit = null;
          this.search = "";
        }
      },
      focusedTagIndex(nextIndex, currentIndex) {
        if (this.editing && this.focusedTagIndex !== null) {
          this.$nextTick(() => {
            // Keep the focused tag in view (when using up/down arrows)
            const container = this.$el.querySelector(".tags-input-selectable-tags");
            const focusedTag = this.$el.querySelectorAll(".tags-input-selectable-tag")[this.focusedTagIndex];

            const movedFocusDown = nextIndex > currentIndex;

            if (movedFocusDown) {
              const containerScrollBottom = container.scrollTop + container.offsetHeight;
              const focusedTagBottom = focusedTag.offsetTop + focusedTag.offsetHeight;

              const isFocusedTagBelowView = focusedTagBottom > containerScrollBottom;

              if (isFocusedTagBelowView) {
                container.scrollTop = focusedTagBottom - container.offsetHeight;
              }
            } else {
              // moved focus up
              const isFocusedTagAboveView = focusedTag.offsetTop < container.scrollTop;

              if (isFocusedTagAboveView) {
                container.scrollTop = focusedTag.offsetTop;
              }
            }
          });
        }
      },
      search() {
        if (this.editing) {
          new Promise((resolve) => {
            if (this.canSearchLocally) {
              this.searchLocally(resolve);
            } else {
              this.searchAPI(resolve);
            }
          }).then(this.updateTagsInOrderBasedOnSearchResult);
        }
      },
      tagsInOrderDuringEdit(nextTags, currentTags) {
        if (this.editing) {
          if (nextTags.length === 0) {
            this.focusedTagIndex = null;
          } else {
            if (currentTags === null) {
              if (nextTags.length >= 1) {
                // Auto-focus the first tag
                window.Vue.nextTick(() => (this.focusedTagIndex = 0));
              }
            }
            if (currentTags !== null) {
              // Any search can yield fewer results than the previous search. Make sure focusedTagIndex is not greater than the collection size.
              this.focusedTagIndex = Math.min(this.tagsInOrderDuringEdit.length - 1, this.focusedTagIndex);
            }
          }
        }
      },
      selectedIdTags() {
        this.fetchPotentiallyMissingTags();
      },
    },
    created() {
      get("/api/v1/tags")
        .then((tags) => {
          this.availableTagsOrNull = tags;
          this.canSearchLocally = tags.length < 100;
          this.fetchPotentiallyMissingTags();
        })
        .catch(unhandled((errorMessage) => alert(errorMessage)));
    },
  });
}

export default !window.Vue ? null : makeTagsInput();
