import AbstractFilter from './AbstractFilter';

export default class extends AbstractFilter {
    getEncodedSubmitValue() { // Overrides base class method
        const checkboxes = Array.from(this._formControlsChild.querySelectorAll('input[type=checkbox]'));

        const checkedValues = checkboxes
            .filter(checkbox => checkbox.checked)
            .map(checkbox => window.encodeURIComponent(checkbox.value));

        if (checkedValues.length === 0) {
            return null;
        }

        return checkedValues.join(',')
    }
    _createFormControlsChild() { // Implementation of abstract method
        const div = document.createElement('div');
        div.className = 'checkbox';

        const selectedValues = typeof this._getInitialValue() === 'string'
            ? this._getInitialValue().split(',')
            : [];

        for (let possibleValue of this._getDefinition().possible_values) {
            const label = document.createElement('label');

            const checkbox = document.createElement('input');
            checkbox.type = 'checkbox';
            checkbox.name = this.getFieldName();
            checkbox.value = possibleValue.key;
            checkbox.checked = selectedValues.includes(possibleValue.key);

            label.appendChild(checkbox);
            label.appendChild(document.createTextNode(possibleValue.label));

            div.appendChild(label);
            div.appendChild(document.createElement('br'));
        }

        return div;
    }
}