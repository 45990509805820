import translate from "./translate";

class AjaxError {
    constructor(rejection) {
        this._rejection = rejection;

        this._validateType(this._rejection.handled, Boolean);
        this._validateType(this._rejection.json, Object);
        this._validateType(this._rejection.reason, String);
        this._validateType(this._rejection.responseText, String);
        this._validateType(this._rejection.status, Number);
    }

    getHttpStatus() {
        return this._rejection.status;
    }

    getMessage() {
        if (this._rejection.json !== undefined) {
            return this._getErrorMessageFromJson();
        }

        return this._getFallbackErrorMessage();
    }

    isGenericallyHandled() {
        return this._rejection.handled === true;
    }

    isNetworkError() {
        return this._rejection.reason === "transaction error";
    }

    getRejectionError() {
        if (!(this._rejection instanceof Error)) {
            return null;
        }

        return this._rejection;
    }

    getAPIErrorCode() {
        if (!this._rejection.json || typeof this._rejection.json.error_code !== "number") {
            return null;
        }

        return this._rejection.json.error_code;
    }

    getAPIErrorContext() {
        if (!this._rejection.json.error_context) {
            return null;
        }

        return this._rejection.json.error_context;
    }

    _getErrorMessageFromJson() {
        if (typeof this._rejection.json.error_message === "string") {
            return this._rejection.json.error_message;
        }

        return this._getFallbackErrorMessage();
    }

    _getErrorMessageSuffixForHttpStatus() {
        if (this._rejection.status === 404) {
            return " (Page not found)";
        } else if (this._rejection.status === 500) {
            return " (Server error)";
        } else if (this._rejection.status === 503) {
            return " (Temporarily unavailable)";
        } else {
            return "";
        }
    }

    _getErrorMessageSuffixForRejectionReason() {
        if (this._rejection.reason === "unauthenticated") {
            return " (Not logged in)";
        } else if (this._rejection.reason === "transaction error") {
            return " (Network error)";
        } else {
            return "";
        }
    }

    _getFallbackErrorMessage() {
        const genericErrorMessage = translate(
            "general",
            "We're sorry, but something went wrong. Please try again or contact Picqer if the problem persists."
        );

        if (this._rejection.reason !== undefined) {
            return genericErrorMessage + this._getErrorMessageSuffixForRejectionReason();
        }
        if (this._rejection.status !== undefined) {
            return genericErrorMessage + this._getErrorMessageSuffixForHttpStatus();
        }

        return genericErrorMessage;
    }

    _validateType(value, type) {
        if (value !== undefined && value.constructor !== type) {
            throw Error("Rejection property is of invalid type");
        }
    }
}

class ManagedErrorHandler {
    constructor(errorHandler) {
        this._errorHandler = errorHandler;
    }

    invokeIfNotGenericallyHandled() {
        return (rejection) => {
            const ajaxError = new AjaxError(rejection);

            if (!ajaxError.isGenericallyHandled()) {
                this._errorHandler(ajaxError.getMessage(), ajaxError);
            }
        };
    }
}

export const unhandled = (errorHandler) => new ManagedErrorHandler(errorHandler).invokeIfNotGenericallyHandled();

const subsequentRequestsMonitor = {};
export const ifLatestResponse = (requestKey, handler) => {
    if (subsequentRequestsMonitor[requestKey] === undefined) {
        subsequentRequestsMonitor[requestKey] = {
            requestsCount: 0,
            highestResponseIndex: -1,
        };
    }
    const thisRequestIndex = subsequentRequestsMonitor[requestKey].requestsCount++;

    return (response) => {
        if (thisRequestIndex > subsequentRequestsMonitor[requestKey].highestResponseIndex) {
            subsequentRequestsMonitor[requestKey].highestResponseIndex = thisRequestIndex;
            const isNewerRequestPending = subsequentRequestsMonitor[requestKey].requestsCount > thisRequestIndex + 1;
            return handler(response, isNewerRequestPending);
        }
    };
};
