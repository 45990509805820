import {hexToRgb} from 'modules/tools'

const template = `
<div class="tags-input-selectable-tag" :style="isSelected ? selectedTagStyle : ''" v-on:click="toggleSelected">
    <div v-if="hasFocus" class="tags-input-selectable-tag-focus-indication"></div>
    <span class="tags-input-selected-tick" :style="'visibility:' + (isSelected ? 'visible' : 'hidden')"><Icon name="check" /></span>
    <div class="tags-input-color-square" :style="'background-color:' + tag.color"></div>
    <div class="tags-input-title">{{ tag.title }}</div>
    <span v-show="isSelected" class="tags-input-unselect-cross"><Icon name="times" /></span>
</div>`;

export function makeSelectableTag() {
    if (! window.Vue) throw Error('Vue is not yet loaded');

    require('components/_vue/icon');

    return window.Vue.extend({
        template,
        props: {
            hasFocus: {
                required: true,
                type: Boolean,
            },
            isSelected: {
                required: true,
                type: Boolean,
            },
            tag: {
                required: true,
                type: Object,
            },
        },
        computed: {
            tagColorAsRgb() {
                var {r, g, b} = hexToRgb(this.tag.color);
                return [r, g, b].join(',');
            },
            selectedTagStyle() {
                return [
                    `background-color:rgba(${this.tagColorAsRgb},0.08)`,
                    `border-bottom-color:transparent`,
                ].join(';');
            },
        },
        methods: {
            toggleSelected() {
                this.$emit('toggle-idtag', this.tag.idtag);
            },
        },
    });
};

export default ! window.Vue ? null : makeSelectableTag();