class MouseManager {
    constructor() {
        this._mouseOverHandlers = new Set();
        this._isMouseOverEventListenerBound = false;

        this._mouseMoveHandlers = new Set();
        this._isMouseMoveEventListenerBound = false;
    }

    addMouseOverHandler(handler) {
        this._mouseOverHandlers.add(handler);

        if (this._isMouseOverEventListenerBound) {
            return;
        }

        this._isMouseOverEventListenerBound = true;
        document.body.addEventListener('mouseover', (event) => {
            this._mouseOverHandlers.forEach(handler => handler(event));
        });
    }
    removeMouseOverHandler(handler) {
        this._mouseOverHandlers.delete(handler);
    }

    addMouseMoveHandler(handler) {
        this._mouseMoveHandlers.add(handler);

        if (this._isMouseMoveEventListenerBound) {
            return;
        }

        this._isMouseMoveEventListenerBound = true;
        document.body.addEventListener('mousemove', (event) => {
            this._mouseMoveHandlers.forEach(handler => handler(event));
        });
    }
    removeMouseMoveHandler(handler) {
        this._mouseMoveHandlers.delete(handler);
    }
}

// Export singleton instance:
window.picqer = window.picqer || {};
if (window.picqer.mouseManager === undefined) {
    window.picqer.mouseManager = new MouseManager();
}
export default window.picqer.mouseManager;