import ActiveTouch from './ActiveTouch';

export default new class {
    constructor() {
        this._activeTouches = [];
        this._onTouchEndedWithoutMoving = [];

        this._bindTouchEventListeners();
    }
    onTouchEndedWithoutMoving(handler) {
        this._onTouchEndedWithoutMoving.push(handler);
    }
    _bindTouchEventListeners() {
        document.addEventListener('touchstart', this._handleTouchStart.bind(this));
        document.addEventListener('touchmove', this._handleTouchMove.bind(this));
        document.addEventListener('touchend', this._handleTouchEnd.bind(this));
    }
    _handleTouchStart(e) {
        for (let  i = 0; i < e.changedTouches.length; i++) {
            this._activeTouches.push(new ActiveTouch(e.changedTouches[i]));
        }
    }
    _handleTouchMove(e) {
        for (let  i = 0; i < e.changedTouches.length; i++) {
            const activeTouch = this._findActiveTouchByIdentifier(e.changedTouches[i].identifier);
            if (activeTouch === undefined) {
                return;
            }
            activeTouch.touchMoved(e.changedTouches[i]);
        }
    }
    _handleTouchEnd(e) {
        for (let  i = 0; i < e.changedTouches.length; i++) {
            const activeTouch = this._findActiveTouchByIdentifier(e.changedTouches[i].identifier);
            if (activeTouch === undefined) {
                return;
            }

            if (! activeTouch.isDrag()) {
                this._invokeTouchEndedWithoutMoving(activeTouch);
            }

            this._stopTrackingTouch(activeTouch);
        }
    }
    _stopTrackingTouch(touch) {
        const index = this._activeTouches.findIndex(activeTouch => activeTouch === touch);
        this._activeTouches.splice(index, 1);
    }
    _findActiveTouchByIdentifier(identifier) {
        return this._activeTouches.find(activeTouch => {
            return activeTouch.identifierEquals(identifier);
        });
    }
    _invokeTouchEndedWithoutMoving(activeTouch) {
        for (let handler of this._onTouchEndedWithoutMoving) {
            if (typeof handler === 'function') {
                handler(activeTouch);
            }
        }
    }
}