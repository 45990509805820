import {makeTagsInput} from 'components/_vue/tags-input';
import AbstractFilter from './AbstractFilter';

export default class extends AbstractFilter {
    getEncodedSubmitValue() { // Overrides base class method
        if (this._vueInstance === undefined) {
            return '';
        }

        const selectedIdTags = this._getSelectedIdTagsFromVueInstance();
        if (selectedIdTags.length === 0) {
            return null;
        }

        return selectedIdTags
            .map(idTag => window.encodeURIComponent(idTag))
            .join(',');
    }
    requiresVue() { // Overrides base class method
        return true;
    }
    initializeVueInstance() {
        this._formControlsChild.closest('.form-controls').classList.add('tags-input');

        let selectedIdTags = [];
        if (typeof this._getInitialValue() === 'string' && this._getInitialValue().length > 0) {
            selectedIdTags = this._getInitialValue().split(',').map(idTag => parseInt(idTag, 10));
        }

        this._vueInstance = new Vue({
            el: this._formControlsChild,
            components: {TagsInput: makeTagsInput()},
            data() {
                return {selectedIdTags};
            },
            computed: {
                idTagsJoined() {
                    return this.selectedIdTags.join(',');
                }
            },
            methods: {
                tagToggled(idTag) {
                    this.selectedIdTags = this.selectedIdTags.includes(idTag)
                        ? this.cloneSelectedIdTagsWithoutId(idTag)
                        : this.cloneSelectedIdTagsWithAddedId(idTag);
                },
                cloneSelectedIdTagsWithAddedId(idTag) {
                    return this.selectedIdTags.concat([idTag]);
                },
                cloneSelectedIdTagsWithoutId(idTag) {
                    const index = this.selectedIdTags.findIndex(id => id === idTag);
                    if (index === -1) {
                        return [...this.selectedIdTags];
                    }

                    const copyBeforeIndex = [...this.selectedIdTags.slice(0, index)];
                    const copyAfterIndex = [...this.selectedIdTags.slice(index + 1)];

                    return copyBeforeIndex.concat(copyAfterIndex);
                },
            }
        });
    }
    _getSelectedIdTagsFromVueInstance() {
        const unpackObservedValue = (observedValue) => JSON.parse(JSON.stringify(observedValue));

        return unpackObservedValue(this._vueInstance.$data.selectedIdTags);
    }
    _createFormControlsChild() {
        const tagsInputWrapper = document.createElement('div');
        tagsInputWrapper.className = 'tags-input-wrapper';
        tagsInputWrapper.setAttribute('v-cloak', '');

        const tagsInput = document.createElement('tags-input');
        tagsInput.setAttribute(':selected-id-tags', 'selectedIdTags');
        tagsInput.setAttribute('v-on:toggle-idtag', 'tagToggled');

        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', this.getFieldName());
        hiddenField.setAttribute(':value', 'idTagsJoined');

        tagsInputWrapper.appendChild(tagsInput);
        tagsInputWrapper.appendChild(hiddenField);

        return tagsInputWrapper;
    }
}