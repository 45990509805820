export default class {
    constructor(definition) {
        this._definition = definition;
        this._formControlsChild = null;
        this._initialValue = null;
    }
    createFormGroup() {
        const formGroup = document.createElement('div');
        formGroup.className = 'form-group form-group-large';

        formGroup.appendChild(this._createFormControlsLabel());
        formGroup.appendChild(this._createFormControlsWrapper());

        return formGroup;
    }
    shouldBeVisible() {
        if (! Array.isArray(this._getDefinition().possible_values)) {
            return true;
        }

        return this._possibleValuesContainsAtLeastOneNonEmptyKey();
    }
    _possibleValuesContainsAtLeastOneNonEmptyKey() {
        return this._getDefinition().possible_values.some(({key}) => {
            return key !== '';
        });
    }
    requiresVue() {
        return false;
    }
    getFieldName() {
        return this._definition.key;
    }
    allowsMultipleValues() {
        return this._definition.multiple_values;
    }
    takeInitialValueFromQueryParams(queryParams) {
        for (let queryParam of queryParams) {
            if (queryParam.getKey() === this._definition.key) {
                this._initialValue = queryParam.getEncodedValue();
                break;
            }
        }

        return this;
    }
    getEncodedSubmitValue() {
        if (this._formControlsChild.value.length === 0) {
            return null;
        }

        return window.encodeURIComponent(this._formControlsChild.value);
    }
    // PROTECTED
    _createFormControlsChild() {
        // Abstract. Must return a single element which will be the child of .form-controls. The element itself may contain multiple children.
    }
    _getInitialValue() {
        // todo may be cleaner to return empty array if definition.type multiple is true
        return this._initialValue;
    }
    _getDefinition() {
        return this._definition;
    }
    // PRIVATE
    _createFormControlsLabel() {
        const label = document.createElement('label');
        label.className = 'form-control-label';
        label.setAttribute('for', `field-${this._definition.key}`);
        label.innerText = this._definition.label;

        return label;
    }
    _createFormControlsWrapper() {
        const formControlsWrapper = document.createElement('div');
        formControlsWrapper.className = 'form-controls';

        this._formControlsChild = this._createFormControlsChild();
        formControlsWrapper.appendChild(this._formControlsChild);

        return formControlsWrapper;
    }
}