import Bugsnag from "@bugsnag/js";
import DropdownButtonsManager from "modules/DropdownButtonsManager";
import enableOnBarcodeScannedEvent from "modules/enableOnBarcodeScannedEvent";
import enablePageDirtyHandling from "modules/enablePageDirtyHandling";
import getScriptParams from "modules/getScriptParams";
import liveBindImageModals from "modules/image-modal";
import liveBindSimpleModals from "modules/simple-modal";
import liveBindServerModals from "modules/server-modal";
import liveBindProductBubbles from "modules/liveBindProductBubbles";
import markupDrivenBehavior from "modules/markup-driven-behavior";
import MobileMenuExpander from "modules/MobileMenuExpander";
import { overrideNativeAlert, overrideNativeConfirm } from "modules/alert";
import { liveBindTooltips } from "modules/TooltipManager";
import TableFiltersManager from "components/TableFiltersManager";
import SupportModalOpenButton from "./_shared/support/SupportModalOpenButton";

const bundleName = "picqer";

window.picqer.prepare(() => {
    enableOnBarcodeScannedEvent();
    enablePageDirtyHandling();
    overrideNativeAlert();
    overrideNativeConfirm();
});

window.picqer.onReady(() => {
    const params = getScriptParams(bundleName);

    window.picqer = window.picqer || {};
    window.picqer = Object.assign({}, window.picqer, {
        assetsPrefix: params.assetsPrefix,
        company: params.company,
        user: params.user,
        versions: params.versions,
    });

    if (params.bugsnagKey && params.bugsnagKey.length > 0 && params.version && params.version.length > 0) {
        Bugsnag.start({
            apiKey: params.bugsnagKey,
            appVersion: params.version,
            user: params.user === null ? null : { id: params.user.id },
            collectUserIp: false,
            autoTrackSessions: false,
        });
    }

    liveBindImageModals();
    liveBindSimpleModals();
    liveBindServerModals();
    liveBindProductBubbles();
    liveBindTooltips();

    new DropdownButtonsManager().bindEvents();
    new SupportModalOpenButton().bindEventsForOpeningSupportModal();
    new MobileMenuExpander().bindEvents();
    new TableFiltersManager().bindEvents();

    document.addEventListener("click", markupDrivenBehavior.onClick);
    document.addEventListener("submit", markupDrivenBehavior.onSubmit);
}, bundleName);
