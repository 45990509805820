// Inspired by https://github.com/rails/jquery-ujs/wiki/Unobtrusive-scripting-support-for-jQuery
import dispatchEvent from 'modules/dispatchEvent'
import httpRequest from 'modules/http-page-request'
import translate from 'modules/translate'

const privateBehaviorManager = new class {

    constructor() {

        this.clickBehaviorSelectors = [
            {
                type: 'confirm',
                selector: 'a[data-confirm], button[data-confirm]',
            },
            {
                type: 'confirmDelete',
                selector: 'a[data-confirm-delete], button[data-confirm-delete]',
            },
            {
                type: 'confirmDeactivate',
                selector: 'a[data-confirm-deactivate], button[data-confirm-deactivate]',
            },
            {
                type: 'allowOnce',
                selector: 'a[data-allow-once], button[data-allow-once], input[type=submit][data-allow-once]',
            },
            {
                type: 'httpMethod',
                selector: 'a[data-method]',
            },
            {
                type: 'unfocusOnClick',
                selector: 'a.btn[target="_blank"], a[data-unfocus-on-click], button[data-unfocus-on-click]',
            },
        ];

        this.submitBehaviorSelectors = [
            {
                type: 'confirm',
                selector: 'form[data-confirm]',
            },
        ];

        this.clickTargetSelectors = this.clickBehaviorSelectors.map(({selector}) => selector).join(',');
        this.submitTargetSelectors = this.submitBehaviorSelectors.map(({selector}) => selector).join(',');

        this.onConfirm = null;

    }

    allowOnce(target) {

        setTimeout(() => {
            if (target.nodeName === 'BUTTON') {
                target.disabled = true;
            } else {
                target.setAttribute('disabled', '');
            }
        }, 0);

    }

    confirm(target, e) {

        if (target.nodeName === 'A') {
            this.confirmForAnchor(target, e);
        } else if (target.nodeName === 'FORM') {
            this.confirmForForm(target, e);
        }

        return window.confirm(target.getAttribute('data-confirm'));

    }

    confirmDelete(target, e) {
        if (target.nodeName === 'A') {
            e.preventDefault();
            this.onConfirm = () => window.location = target.href;
        }

        const trans = key => translate('modal_alert', key);

        return confirm(trans('Are you sure you want to delete this item?'), trans('Yes, delete'), trans('No, cancel'));
    }

    confirmDeactivate(target, e) {
        if (target.nodeName === 'A') {
            e.preventDefault();
            this.onConfirm = () => window.location = target.href;
        }

        const trans = key => translate('modal_alert', key);
        const message = target.getAttribute('data-confirm-deactivate');

        return message != undefined
            ? confirm(trans(`Are you sure you want to deactivate this item?\n\n${message}`), trans('Yes, deactivate'), trans('No, cancel'))
            : confirm(trans('Are you sure you want to deactivate this item?'), trans('Yes, deactivate'), trans('No, cancel'))
    }

    confirmForAnchor(anchor, e) {
        e.preventDefault();
        this.onConfirm = () => window.location = anchor.href;
    }

    confirmForForm(form, e) {
        e.preventDefault();
        this.onConfirm = () => {
            const submitButton = form.querySelector('input[type=submit]');
            if (submitButton !== null) {
                submitButton.disabled = true;
            }
            form.submit();
        }
    }

    getClickTarget(element) {
        if (!(element instanceof window.Element)) {
            return null;
        }
        return element.closest(this.clickTargetSelectors);
    }

    getSubmitTarget(element) {
        if (!(element instanceof window.Element)) {
            return null;
        }
        return element.closest(this.submitTargetSelectors);
    }

    httpMethod(target, e) {
        httpRequest(target.getAttribute('data-method'), target.href);
        this.onConfirm = null;
    }

    onClick(e) {
        const target = this.getClickTarget(e.target);
        if (target !== null) {
            this.runBehaviorsForEventTarget(e, target, this.clickBehaviorSelectors);
        }
    }

    onSubmit(e) {
        const target = this.getSubmitTarget(e.target);
        if (target !== null) {
            this.runBehaviorsForEventTarget(e, target, this.submitBehaviorSelectors);
        }
    }

    runBehaviorsForEventTarget(e, target, behaviorSelectors) {
        this.onConfirm = null;

        const queuedHandlers = [];
        let confirmPromise = null;

        for (let {type, selector} of behaviorSelectors) {

            if (target.matches(selector)) {

                switch (type) {
                    case 'confirm': {
                        confirmPromise = this.confirm(target, e);
                        break;
                    }
                    case 'confirmDelete': {
                        confirmPromise = this.confirmDelete(target, e);
                        break;
                    }
                    case 'confirmDeactivate': {
                        confirmPromise = this.confirmDeactivate(target, e);
                        break;
                    }
                    case 'unfocusOnClick': {
                        queuedHandlers.push(this.unfocusOnClick.bind(this));
                        break;
                    }
                    case 'allowOnce': {
                        queuedHandlers.push(this.allowOnce.bind(this));
                        break;
                    }
                    case 'httpMethod': {
                        e.preventDefault() // Deliberately preventDefault here, not in `httpMethod` because of Firefox issue - https://www.pivotaltracker.com/story/show/157395911
                        queuedHandlers.push(this.httpMethod.bind(this));
                        break;
                    }
                    default: {
                        break;
                    }
                }

            }

        }

        (confirmPromise || Promise.resolve()).then(wasConfirmed => {

            if (wasConfirmed === false) {
                // Was the cancel button pressed? Then don't run any handlers.
                return;
            }

            for (let handler of queuedHandlers) {
                handler(target, e);
            }

            if (typeof this.onConfirm === 'function') {
                this.onConfirm();
            }

            if (wasConfirmed === true) {
                dispatchEvent(target, 'picqer:markup-driven-behavior-confirmed');
            }

        });
    }

    unfocusOnClick(target) {
        target.blur();

        if (document.activeElement === target) {
            // IE11 fails to deactivate the anchor with blur(). Luckily, disabling and re-enabling has the same effect, so this is our fallback. Does not impact behavior of data-allow-once.
            target.setAttribute('disabled', '');
            target.removeAttribute('disabled');
        }
    }

};

export default {
    onClick: privateBehaviorManager.onClick.bind(privateBehaviorManager),
    onSubmit: privateBehaviorManager.onSubmit.bind(privateBehaviorManager),
}
