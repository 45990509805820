import mouseManager from 'modules/mouseManager';
import Tooltip from './Tooltip';

class TooltipManager {
    constructor() {
        this._visibleTooltipForTrigger = new Map();
    }
    bodyMouseOver(event) {
        const mouseOverElement = event.target;

        const triggerElement = this._getNearestElementWithTooltip(mouseOverElement);
        if (! (triggerElement instanceof window.HTMLElement)) {
            return;
        }

        const tooltip = this._getTooltipFor(triggerElement);
        this._hideTooltipOnMouseOut(tooltip, mouseOverElement);

        this._visibleTooltipForTrigger.set(triggerElement, tooltip);
        triggerElement.dataset.tooltipId = tooltip.getTooltipId();
    }
    _getNearestElementWithTooltip(element) {
        while (element instanceof window.HTMLElement) {
            if (element.hasAttribute('data-tooltip')) {

                return element;
            }
            element = element.parentElement;
        }

        return null;
    }
    _getTooltipFor(triggerElement) {
        const isTooltipAlreadyVisible = this._visibleTooltipForTrigger.has(triggerElement);

        if (isTooltipAlreadyVisible) {
            const tooltip = this._visibleTooltipForTrigger.get(triggerElement);
            tooltip.cancelHide();

            return tooltip;
        }

        return new Tooltip()
            .onTooltipRemoved(() => this._visibleTooltipForTrigger.delete(triggerElement))
            .showTooltipFor(triggerElement);
    }
    _hideTooltipOnMouseOut(tooltip, mouseOutTarget) {
        const mouseOutHandlerOnce = (e => {
            mouseOutTarget.removeEventListener('mouseout', mouseOutHandlerOnce);
            tooltip.scheduleHide();
        }).bind(this);

        mouseOutTarget.addEventListener('mouseout', mouseOutHandlerOnce);
    }
}

export function liveBindTooltips() {
    const tooltipManager = new TooltipManager();
    mouseManager.addMouseOverHandler(tooltipManager.bodyMouseOver.bind(tooltipManager));
}

export default TooltipManager;