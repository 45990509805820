export default function (documentFragment) {
    if (documentFragment instanceof window.DocumentFragment) {
        if (! (documentFragment.firstElementChild instanceof window.HTMLElement)) {
            // Edge does not support firstElementChild, so use fallback method.
            return Array.from(documentFragment.childNodes)
                .find(node => node.nodeType === window.Node.ELEMENT_NODE);
        }

        return documentFragment.firstElementChild;
    }

    if (documentFragment instanceof window.HTMLElement) {
        return documentFragment;
    }

    throw Error('Cannot getFirstElementFromDocumentFragment; argument is neither DocumentFragment nor HTMLElement');
}