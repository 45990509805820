import { loadScript, loadStyleSheet } from "./assetLoader";

class AbstractDatePicker {
    constructor(element) {
        this.element = element;
        this.onDateChangedHandler = null;

        if (!(this.element instanceof window.HTMLElement)) {
            if (window.picqer.dev) {
                console.warn("DatePicker only valid on HTMLElement");
            }
            this.element = null;
        }

        this.disableAutoComplete();
    }

    disableAutoComplete() {
        const isTextInput = this.element instanceof window.HTMLElement && this.element.matches("input[type=text]");

        if (!isTextInput) {
            return;
        }

        this.element.autocomplete = "off";
    }

    getDateString(date) {
        return [date.getFullYear(), this.padTwo(date.getMonth() + 1), this.padTwo(date.getDate())].join("-");
    }

    invoke(handler, ...args) {
        if (typeof handler === "function") {
            handler(...args);
        }
    }

    onDateChanged(handler) {
        this.onDateChangedHandler = handler;
    }

    padTwo(value) {
        const string = value.toString();
        return string.length >= 2 ? string : `0${string}`;
    }
}

const privateBootstrapDatePickerUtility = new (class {
    constructor() {
        this.defaultSettings = {
            format: "dd-mm-yyyy",
            language: "nl",
        };
        this.promises = {
            script: null,
            languageScript: null,
            styleSheet: null,
        };
    }

    // Returns whatever is stored in `window.picqer.translations.datepicker[key]`, or the default (because in the fulfilment-portal and in the admin, window.picqer.translations is not set).
    getSetting(key) {
        return (
            (window.picqer && window.picqer.translations && window.picqer.translations.datepicker[key]) ||
            this.defaultSettings[key]
        );
    }

    loadScript() {
        return this.promises.script || (this.promises.script = loadScript("/scripts/bootstrap-datepicker.min.js"));
    }

    loadLanguageScript() {
        if (this.promises.languageScript === null) {
            const language = this.getSetting("language");
            this.promises.languageScript =
                language !== "en" ? loadScript(`/scripts/bootstrap-datepicker.${language}.min.js`) : Promise.resolve();
        }

        return this.promises.languageScript;
    }

    loadStyleSheet() {
        return (
            this.promises.styleSheet || (this.promises.styleSheet = loadStyleSheet("/css/bootstrap-datepicker.min.css"))
        );
    }
})();

class BootstrapDatePicker extends AbstractDatePicker {
    constructor(element, bootstrapOptions) {
        super(element);
        this.bootstrapOptions = bootstrapOptions;

        if (this.element === null) {
            return;
        }

        const scriptLoader = privateBootstrapDatePickerUtility.loadScript();
        const styleSheetLoader = privateBootstrapDatePickerUtility.loadStyleSheet();

        Promise.all([styleSheetLoader, scriptLoader]).then(() => {
            privateBootstrapDatePickerUtility.loadLanguageScript().then(() => {
                this.assignDatePicker();
            });
        });
    }

    assignDatePicker() {
        $(this.element)
            .datepicker(
                Object.assign(
                    {
                        autoclose: true,
                        calendarWeeks: true,
                        forceParse: false,
                        format: privateBootstrapDatePickerUtility.getSetting("format"),
                        language: privateBootstrapDatePickerUtility.getSetting("language"),
                        orientation: "bottom auto",
                        todayHighlight: true,
                    },
                    this.bootstrapOptions
                )
            )
            .on("changeDate", ({ date }) => {
                this.invoke(this.onDateChangedHandler, this.getDateString(date));
            });
    }

    setDate(dateObject) {
        $(this.element).datepicker("update", dateObject);
    }
}

export default (element, options) => new BootstrapDatePicker(element, options);
