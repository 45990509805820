import { unhandled } from "modules/ajax-utility";
import { get } from "modules/ajax-vanilla";
import { loadScript } from "modules/assetLoader";
import translate from "modules/translate";
import DateFilter from "./Filters/DateFilter";
import SelectAnyFilter from "./Filters/SelectAnyFilter";
import SelectOneFilter from "./Filters/SelectOneFilter";
import TagsFilter from "./Filters/TagsFilter";
import QueryParam from "./QueryParam";

export default class {
    constructor(filterType) {
        this._filterType = filterType;
        this._filters = [];
    }

    buildForm() {
        if (this._buildFormPromise === undefined) {
            this._buildFormPromise = get(`/filters/${this._filterType}`)
                .then(this._createForm.bind(this))
                .catch(
                    unhandled((errorMessage) => {
                        this._buildFormPromise = undefined;
                        throw Error(errorMessage);
                    })
                );
        }

        return this._buildFormPromise;
    }

    _createForm(filterDefinitions) {
        const form = document.createElement("form");
        form.className = "form-horizontal";
        form.addEventListener("submit", this._submitForm.bind(this));

        this._filters = filterDefinitions
            .map(this._createFilter.bind(this))
            .filter((filter) => filter !== null && filter.shouldBeVisible());

        for (let filter of this._filters) {
            form.appendChild(filter.takeInitialValueFromQueryParams(this._getFilterQueryParams()).createFormGroup());

            if (filter.requiresVue()) {
                this._loadVue().then(() => filter.initializeVueInstance());
            }
        }

        if (this._filters.length > 0) {
            const submitButton = document.createElement("input");
            submitButton.className = "btn btn-primary";
            submitButton.type = "submit";
            submitButton.value = this._translate("Apply filters");
            form.appendChild(submitButton);
        } else {
            const noFilters = document.createElement("div");
            noFilters.innerText = this._translate("There are no filters for this list");
            form.appendChild(noFilters);
        }

        return form;
    }

    _loadVue() {
        if (!(this._vueLoaderPromise instanceof window.Promise)) {
            const vueScriptFileName = `vue${window.picqer.dev === true ? "" : ".min"}.js`;
            this._vueLoaderPromise = loadScript(`/scripts/${vueScriptFileName}`).catch((error) => {
                alert("Could not load all filters.");
                throw error;
            });
        }

        return this._vueLoaderPromise;
    }

    _createFilter(definition) {
        if (definition.type === "date") {
            return new DateFilter(definition);
        }

        if (definition.type === "tags") {
            return new TagsFilter(definition);
        }

        if (Array.isArray(definition.possible_values)) {
            return definition.multiple_values ? new SelectAnyFilter(definition) : new SelectOneFilter(definition);
        }

        return null;
    }

    _getQueryParams() {
        if (this._queryParams === undefined) {
            const queryString = window.location.search.replace("?", "");
            this._queryParams =
                queryString !== ""
                    ? (this._queryParams = queryString.split("&").map((pair) => new QueryParam(pair)))
                    : [];
        }

        return this._queryParams;
    }

    _getFilterQueryParams() {
        if (this._filterQueryParams === undefined) {
            this._filterQueryParams = this._getQueryParams().filter((queryParam) => queryParam.isFilter());
        }

        return this._filterQueryParams;
    }

    _getNonFilterQueryParams() {
        if (this._nonFilterQueryParams === undefined) {
            this._nonFilterQueryParams = this._getQueryParams().filter((queryParam) => !queryParam.isFilter());
        }

        return this._nonFilterQueryParams;
    }

    _submitForm(event) {
        event.preventDefault();

        const newFilterQueryParams = [];
        for (let filter of this._filters) {
            const encodedValue = filter.getEncodedSubmitValue();
            if (encodedValue !== null) {
                newFilterQueryParams.push(new QueryParam(filter.getFieldName(), encodedValue));
            }
        }

        const nonFilterQueryParamsExceptPage = this._getNonFilterQueryParams()
            .filter((queryParam) => queryParam.getKey() !== "page")
            .map((queryParam) => queryParam.clone());

        this._refreshWithQueryParams(nonFilterQueryParamsExceptPage.concat(newFilterQueryParams));
    }

    _refreshWithQueryParams(queryParams) {
        let queryString = "";
        if (queryParams.length > 0) {
            queryString = `?${queryParams.map((queryParam) => queryParam.joinPair()).join("&")}`;
        }

        window.location = window.location.pathname + queryString;
    }

    _translate(key, namespace = "index_table_filters") {
        return translate(namespace, key);
    }
}
