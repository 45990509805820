import { iconClassName } from "modules/icon";

const template = `
<span v-if="shouldAddWrapperClass" :class="wrapperClassNames">
    <i :class="iconClassNames"></i>
</span>
<i v-else :class="iconClassNames"></i>
`;

if (window.Vue) {
  window.Vue.component("Icon", {
    template,
    props: {
      name: { type: String, required: true },
      spin: { type: Boolean, required: false, default: false },
      iconClass: { type: String, required: false, default: null },
      wrapperClass: { type: String, required: false, default: null },
      fixedWidth: { type: Boolean, required: false, default: false },
      size: {
        type: String,
        default: null,
        required: false,
        validator: (value) => [null, "xs", "sm", "lg", "2x", "3x", "5x", "7x", "10x"].includes(value),
      },
      type: {
        type: String,
        default: null,
        required: false,
        validator: (value) => [null, "regular", "solid"].includes(value),
      },
    },
    computed: {
      iconClassNames() {
        return iconClassName(this.name, {
          type: this.type,
          size: this.size,
          spin: this.spin,
          iconClass: this.iconClass,
          fixedWidth: this.fixedWidth,
        });
      },
      wrapperClassNames() {
        return ["icon-wrapper", this.wrapperClass].join(" ");
      },
      shouldAddWrapperClass() {
        return this.wrapperClass !== null && this.wrapperClass !== undefined && this.wrapperClass.length > 0;
      },
    },
  });
}
