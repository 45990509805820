import {unhandled} from 'modules/ajax-utility';
import {get} from 'modules/ajax-vanilla';
import SupportModal from './SupportModal';

export default class {
    constructor() {
        this._supportModalPromise = null;
        this._isSupportModalOpen = false;
    }
    bindEventsForOpeningSupportModal() {
        document.querySelectorAll('.open-support-modal-button').forEach(item => {
            item.addEventListener('click', this._openSupportModal.bind(this));
        })
    }
    _openSupportModal(event) {
        event.preventDefault();

        if (this._isSupportModalOpen) {
            return;
        }

        this._isSupportModalOpen = true;

        this._getSupportModalInstance()
            .then(supportModal => supportModal.open())
            .catch(unhandled(errorMessage => {
                this._supportModalPromise = null;
                alert(errorMessage);
            }));
    }
    _getSupportModalInstance() {
        if (! (this._supportModalPromise instanceof window.Promise)) {
            this._supportModalPromise = this._getSupportModalRoot().then(modalRoot => {
                return new SupportModal(modalRoot).onClosed(() => this._isSupportModalOpen = false);
            });
        }

        return this._supportModalPromise;
    }
    _getSupportModalRoot() {
        return get('/support-modal').then(this._insertModalInDOM.bind(this))
    }
    _insertModalInDOM(modalMarkup) {
        const supportWrapper = document.createElement('div');
        supportWrapper.id = 'support-wrapper';
        supportWrapper.insertAdjacentHTML('beforeend', modalMarkup);

        document.body.appendChild(supportWrapper);

        return supportWrapper.querySelector('.picqer-modal');
    }
}