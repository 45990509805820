
// Wraps the [element] inside the [wrappingElement]. If [wrappingElement] is a string, it must be a tagName (such as "div"). Otherwise it's expected to be a pre-created element. Returns the [wrappingElement] for convenience.
export function wrap(element, wrappingElement) {
    if (typeof wrappingElement === 'string') {
        wrappingElement = document.createElement(wrappingElement);
    }
    element.parentNode.insertBefore(wrappingElement, element);
    wrappingElement.insertBefore(element, null);
    return wrappingElement;
}

// Creates new elements based on the given [htmlString] and returns them all.
export function createElements(htmlString) {
    var div = document.createElement('div');
    div.innerHTML = htmlString.toString().trim();
    return div.childNodes;
}

// Creates a new element based on the given [htmlString] and returns that element. Only returns the first element.
export function createElement(htmlString) {
    return createElements(htmlString)[0];
}

// Returns a boolean value indicating if [element] has [ancestorElement] in its parent chain.
export function isDescendantOf(element, ancestorElement) {
    while (element != null) {
        if (element.parentNode === ancestorElement) {
            return true;
        }
        element = element.parentNode;
    }
    return false;
}

// Same as isDescendantOf() but also true if [element] equals [ancestorElement].
export function isSelfOrDescendantOf(element, ancestorElement) {
    return element === ancestorElement || isDescendantOf(element, ancestorElement);
}

// Returns a boolean value indicating if [element] has an element that matches [ancestorSelector] in its parent chain.
// Optionally supply [limit] whose value is the max number of ancestors to check (limit 0 always returns false, see isSelfOrDescendantOfSelector() instead).
export function isDescendantOfSelector(element, ancestorSelector, limit = -1) {
    var i = 0;
    while (element != null) {
        if (limit > -1 && i++ === limit) {
            return false;
        }
        if (element.parentNode != null && $(element.parentNode).is(ancestorSelector)) {
            return true;
        }
        element = element.parentNode;
    }
    return false;
}

// Same as isDescendantOfSelector but also checks the element itself.
export function isSelfOrDescendantOfSelector(element, ancestorSelector, limit = -1) {
    return $(element).is(ancestorSelector) || isDescendantOfSelector(element, ancestorSelector, limit);
}

// Out of [possibleAncestors], returns the first one of that [element] descends from.
export function findAncestor(element, possibleAncestors, allowSelf = false) {
    for (var i = 0; i < possibleAncestors.length; i++) {
        if ((allowSelf ? isSelfOrDescendantOf : isDescendantOf)(element, possibleAncestors[i])) {
            return possibleAncestors[i];
        }
    }
    return null;
}

// Same as findAncestor(), but also true if [element] equals the iterated ancestor.
export function findSelfOrAncestor(element, possibleAncestors) {
    return findAncestor(element, possibleAncestors, true);
}

// Returns the element closest in the parent chain (including self) that passes the predicate test. The predicate is invoked with one argument: the current element to be tested. Returns null if no match.
// Optionally supply [limit] to prevent [predicate] being called more often than necessary. A value of 0 only checks the element itself, -1 is unlimited.
export function closest(element, predicate, limit = -1) {
    var i = 0;
    while (element != null && element !== document.documentElement) {
        if (predicate(element) === true) {
            return element;
        }
        if (limit > -1 && i++ === limit) {
            return null;
        }
        element = element.parentNode;
    }
    return null;
}

export function getPosition(element) {
    var box = element.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top = box.top + scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    return {top: Math.round(top), left: Math.round(left)};
}

// Same as closest() but accepts a [selector]. JQuery is used for this.
export function closestSelector(element, selector, limit = -1) {
    if (element instanceof $) {
        element = element[0];
    }
    var i = 0;
    while (element != null) {
        if ($(element).is(selector)) {
            return element;
        }
        if (limit > -1 && i++ === limit) {
            return null;
        }
        element = element.parentNode;
    }
    return null;
}

export function removeElement(element) {
    element && element.parentNode && element.parentNode.removeChild(element);
}