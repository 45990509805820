const MINIMUM_OFFSET_FROM_SCREEN_EDGE_RIGHT = 20;

export default class {
    constructor() {
        this._measurementsBeforeShowingTooltip = null;
        this._removalTimeout = null;
        this._onTooltipRemoved = null;
        this._tooltipId = Math.random().toString();
        this._checkTriggerExistsInDOMInterval = null;
    }
    onTooltipRemoved(handler) {
        this._onTooltipRemoved = handler;

        return this;
    }
    showTooltipFor(triggerElement) {
        this._measurementsBeforeShowingTooltip = {
            bodyWidth: document.body.offsetWidth,
            triggerRectangle: triggerElement.getBoundingClientRect(),
        }

        const tooltipText = this._getTooltipText(triggerElement);
        this._tooltipElement = this._insertTooltipElementInDOM(tooltipText);
        this._positionTooltip(this._tooltipElement);
        this._fadeInTooltip(this._tooltipElement);

        this._checkTriggerExistsInDOMInterval = this._setIntervalToRemoveTooltipIfTriggerGetsRemovedFromDOM(triggerElement);

        return this;
    }
    scheduleHide() {
        this._removalTimeout = setTimeout(() => {
            this._removalTimeout = null;
            this._removeTooltip();
        }, 10);
    }
    cancelHide() {
        clearTimeout(this._removalTimeout);
    }
    getTooltipId() {
        return this._tooltipId;
    }

    _getTooltipText(triggerElement) {
        const title = triggerElement.getAttribute('title') || '';

        if (title.length > 0) {
            triggerElement.dataset.title = title;
            triggerElement.setAttribute('title', '');
        }

        return triggerElement.dataset.title;
    }
    _setIntervalToRemoveTooltipIfTriggerGetsRemovedFromDOM(triggerElement) {
        return setInterval(() => {
            if (! document.body.contains(triggerElement)) {
                this._removeTooltip();
            }
        }, 1000);
    }
    _removeTooltip() {
        clearInterval(this._checkTriggerExistsInDOMInterval);

        if (! (this._tooltipElement instanceof window.HTMLElement)) {
            return;
        }

        if (typeof this._onTooltipRemoved === 'function') {
            this._onTooltipRemoved();
        }

        this._tooltipElement.remove();
    }
    _insertTooltipElementInDOM(tooltipText) {
        const tooltipElement = document.createElement('div');
        tooltipElement.className = 'tooltip top';
        tooltipElement.setAttribute('data-tooltip-id', this._tooltipId);

        const arrowElement = document.createElement('div');
        arrowElement.className = 'tooltip-arrow';
        tooltipElement.appendChild(arrowElement);

        const innerElement = document.createElement('div');
        innerElement.className = 'tooltip-inner';
        innerElement.innerText = tooltipText;
        tooltipElement.appendChild(innerElement);

        document.body.appendChild(tooltipElement);
        this._applyIntrinsicTooltipWidthAsStyleProperty(tooltipElement);

        return tooltipElement;
    }
    _applyIntrinsicTooltipWidthAsStyleProperty(tooltipElement) {
        // Positioning the tooltip can alter width, but CSS and the tooltip-text should determine the size, once.
        // To prevent funky sizes and position issues, apply the current intrinsic width as inline style, "locking" it as is.
        const intrinsicTooltipWidth = Math.ceil(tooltipElement.getBoundingClientRect().width);
        tooltipElement.style.width = intrinsicTooltipWidth + 'px';
    }
    _positionTooltip(tooltipElement) {
        const {triggerRectangle} = this._measurementsBeforeShowingTooltip;
        const tooltipRectangle = tooltipElement.getBoundingClientRect();

        const triggerCenter = triggerRectangle.left + triggerRectangle.width / 2;
        const tooltipTargetX = triggerCenter - tooltipRectangle.width / 2;
        const tooltipTargetY = window.pageYOffset + triggerRectangle.top - tooltipRectangle.height;

        tooltipElement.style.left = tooltipTargetX + 'px';
        tooltipElement.style.top = tooltipTargetY + 'px';

        if (this._moveTooltipLeftIfOutsideWindowBounds(tooltipElement)) {
            const tooltipArrow = tooltipElement.querySelector('.tooltip-arrow');
            this._repositionTooltipArrow(tooltipArrow, triggerCenter);
        }
    }
    _moveTooltipLeftIfOutsideWindowBounds(tooltipElement) {
        const tooltipRectangle = tooltipElement.getBoundingClientRect();
        const right = tooltipRectangle.left + tooltipRectangle.width;
        const maxRight = this._measurementsBeforeShowingTooltip.bodyWidth - MINIMUM_OFFSET_FROM_SCREEN_EDGE_RIGHT;

        if (maxRight > right) {
            return false;
        }

        const distanceToMoveLeft = right - maxRight;
        tooltipElement.style.left = (tooltipRectangle.left - distanceToMoveLeft) + 'px';

        return true;
    }
    _repositionTooltipArrow(tooltipArrow, triggerCenter) {
        const {left: arrowCurrentX} = tooltipArrow.getBoundingClientRect();
        const arrowTargetX = triggerCenter; // Do not correct for width * 0.5 - css does that.

        const distanceToShiftArrow = arrowTargetX - arrowCurrentX;
        tooltipArrow.style.left = Math.round(tooltipArrow.offsetLeft + distanceToShiftArrow) + 'px';
    }
    _fadeInTooltip(tooltipElement) {
        tooltipElement.className += ' fade in';
    }
}