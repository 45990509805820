class Request {
    constructor(url, data, method) {
        this.data = data || {};
        this.method = method;
        this.url = url;
    }

    send() {
        const formElement = document.createElement("form");
        formElement.action = this.url;
        formElement.method = "POST";
        document.body.appendChild(formElement);

        const token = document.querySelector('meta[name="csrf-token"]');
        if (token) {
            const hiddenTokenInput = document.createElement("input");
            hiddenTokenInput.name = "_token";
            hiddenTokenInput.type = "hidden";
            hiddenTokenInput.value = token.content;
            formElement.appendChild(hiddenTokenInput);
        }

        if (this.method !== "POST") {
            const hiddenMethodInput = document.createElement("input");
            hiddenMethodInput.name = "_method";
            hiddenMethodInput.type = "hidden";
            hiddenMethodInput.value = this.method;
            formElement.appendChild(hiddenMethodInput);
        }

        for (let key in this.data) {
            // eslint-disable-next-line no-prototype-builtins
            if (this.data.hasOwnProperty(key)) {
                const hiddenInput = document.createElement("input");
                hiddenInput.name = key;
                hiddenInput.type = "hidden";
                hiddenInput.value = this.data[key];
                formElement.appendChild(hiddenInput);
            }
        }

        formElement.submit();
    }
}

export const httpPost = (url, data) => new Request(url, data, "POST").send();
export const httpPut = (url, data) => new Request(url, data, "PUT").send();
export const httpDelete = (url, data) => new Request(url, data, "DELETE").send();
export default (method, url, data) => new Request(url, data, method).send();
