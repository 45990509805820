import touchHandler from 'modules/touchHandler';

const BOOTSTRAP_PARENT_CLASS_FOR_VISIBLE_DROPDOWN_MENU = 'open';

export default class {
    constructor() {
        this._currentVisibleDropdownMenu = null;
    }
    bindEvents() {
        this._bindClickEventForDropdownMenus();
        this._bindTouchEventForClosingDropdownMenus();
    }
    _bindClickEventForDropdownMenus() {
        document.body.addEventListener('click', event => {
            const clickedDropdownMenuTrigger = event.target.closest('.dropdown-toggle');
            if (! (clickedDropdownMenuTrigger instanceof window.HTMLElement)) {
                this._hideCurrentVisibleDropdownMenu();

                return;
            }

            event.preventDefault();
            this._dropdownMenuTriggerClicked(clickedDropdownMenuTrigger);
        });
    }
    _bindTouchEventForClosingDropdownMenus() {
        touchHandler.onTouchEndedWithoutMoving(touch => {
            if (! this._anyDropdownMenuIsVisible()) {

                return;
            }

            if (this._isTouchOutsideCurrentVisibleDropdownMenu(touch)) {
                this._hideCurrentVisibleDropdownMenu();
            }
        });
    }
    _dropdownMenuTriggerClicked(dropdownMenuTrigger) {
        const dropdownMenu = dropdownMenuTrigger.parentElement.querySelector('.dropdown-menu');
        if (! (dropdownMenu instanceof window.HTMLElement)) {

            return;
        }

        const wasDropdownMenuAlreadyVisible = dropdownMenu === this._currentVisibleDropdownMenu;
        this._hideCurrentVisibleDropdownMenu();

        if (! wasDropdownMenuAlreadyVisible) {
            this._showDropdownMenu(dropdownMenu);
        }
    }
    _showDropdownMenu(dropdownMenu) {
        dropdownMenu.parentElement.classList.add(BOOTSTRAP_PARENT_CLASS_FOR_VISIBLE_DROPDOWN_MENU);
        this._currentVisibleDropdownMenu = dropdownMenu;
    }
    _hideDropdownMenu(dropdownMenu) {
        dropdownMenu.parentElement.classList.remove(BOOTSTRAP_PARENT_CLASS_FOR_VISIBLE_DROPDOWN_MENU);
    }
    _hideCurrentVisibleDropdownMenu() {
        if (! this._anyDropdownMenuIsVisible()) {
            return;
        }

        this._hideDropdownMenu(this._currentVisibleDropdownMenu);
        this._currentVisibleDropdownMenu = null;
    }
    _isTouchOutsideCurrentVisibleDropdownMenu(touch) {
        const target = touch.getTarget();
        const dropdownMenuTrigger = this._getTriggerForDropdownMenu(this._currentVisibleDropdownMenu);

        const wasDropdownMenuTriggerPressed = this._isElementDescendantOf(target, dropdownMenuTrigger);
        const wasDropdownMenuPressed = this._isElementDescendantOf(target, this._currentVisibleDropdownMenu);

        return ! wasDropdownMenuTriggerPressed && ! wasDropdownMenuPressed;
    }
    _anyDropdownMenuIsVisible() {
        return this._currentVisibleDropdownMenu instanceof window.HTMLElement;
    }
    _getTriggerForDropdownMenu(dropdownMenu) {
        return dropdownMenu.parentElement.querySelector('.dropdown-toggle');
    }
    _isElementDescendantOf(element, ancestorElement) {
        while (element instanceof window.HTMLElement) {
            if (element === ancestorElement) {

                return true;
            }
            element = element.parentElement;
        }

        return false;
    }
}