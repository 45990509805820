export const iconHtml = (name, configuration) => {
  return `<i class="${iconClassName(name, configuration)}"></i>`;
};

export const iconClassName = (name, configuration) => {
  const iconConfiguration = getIconConfiguration(name, configuration?.type);

  return getIconClass(iconConfiguration, configuration);
};

const getIconClass = (iconConfiguration, configuration) => {
  const typeClass = `fa${iconConfiguration.type.substr(0, 1)}`;
  const iconClasses = [typeClass, `fa-${iconConfiguration.icon}`];

  if (configuration?.spin || iconConfiguration.spin) {
    iconClasses.push("fa-spin");
  }

  if (configuration?.size) {
    iconClasses.push(`fa-${configuration.size}`);
  }

  if (configuration?.fixedWidth) {
    iconClasses.push("fa-fw");
  }

  if (configuration?.iconClass && configuration?.iconClass.length > 0) {
    iconClasses.push(configuration?.iconClass);
  }

  return iconClasses.join(" ");
};

const getIconConfiguration = (name, type) => {
  return {
    icon: window.picqer?.icons[name]?.icon ?? name,
    spin: window.picqer?.icons[name]?.spin ?? false,
    type: type || (window.picqer?.icons[name]?.type ?? "regular"),
  };
};
