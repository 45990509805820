import {unhandled} from 'modules/ajax-utility';
import {get, post} from 'modules/ajax-vanilla';
import getFirstElementFromDocumentFragment from 'modules/getFirstElementFromDocumentFragment';
import translate from 'modules/translate';
import Modal from './Modal';
import {iconClassName} from "modules/icon";

export default class {
    static createAndLoad(url, data, method = 'get') {
        const provisionalModal = new Modal(this._createProvisionalModalElement());
        provisionalModal.open();

        const promise = method === 'post' ? post(url, data) : get(url, data);

        return promise
            .catch(unhandled(this._failedToLoadModalHtml.bind(this, provisionalModal)))
            .then(modalHtml => {
                this._rejectWithSilentErrorIfModalWasClosedWhileLoading(provisionalModal);
                return this._replaceProvisionalModal(provisionalModal, modalHtml);
            });
    }
    static _replaceProvisionalModal(provisionalModal, replacementHtml) {
        const documentFragment = document.createRange().createContextualFragment(replacementHtml);
        const modalElementFromHtml = getFirstElementFromDocumentFragment(documentFragment);

        provisionalModal.markBackdropForRecycling();
        provisionalModal.close();

        const actualContentModal = new Modal(modalElementFromHtml)
            .recycleExistingBackdrop(provisionalModal.getModalBackdrop());
        actualContentModal.open();

        return actualContentModal;
    }
    static _failedToLoadModalHtml(provisionalModal, errorMessage) {
        const error = provisionalModal.isModalOpen()
            ? Error(errorMessage)
            : this._createSilentError();

        provisionalModal.close();
        throw error;
    }
    static _rejectWithSilentErrorIfModalWasClosedWhileLoading(provisionalModal) {
        if (provisionalModal.isModalOpen()) {
            return;
        }
        throw this._createSilentError();
    }
    static _createProvisionalModalElement() {
        const modal = document.createElement('div');
        modal.className = 'picqer-modal';

        const content = document.createElement('div');
        content.className = 'picqer-modal-content';

        const body = document.createElement('div');
        body.className = 'picqer-modal-body';
        body.appendChild(this._createBigLoadingMessage());

        const footer = document.createElement('div');
        footer.className = 'picqer-modal-footer';

        content.appendChild(this._createHeader());
        content.appendChild(body);
        content.appendChild(footer);

        modal.appendChild(content);

        return modal;
    }
    static _createHeader() {
        const header = document.createElement('div');
        header.className = 'picqer-modal-header';

        const heading = document.createElement('h4');
        heading.className = 'modal-title';

        const closeButton = document.createElement('button');
        closeButton.className = 'close-modal';
        closeButton.type = 'button';
        closeButton.innerHTML = '&times;';

        header.appendChild(heading);
        header.appendChild(closeButton);

        return header;
    }
    static _createBigLoadingMessage() {
        const bigLoadingMessage = document.createElement('div');
        bigLoadingMessage.className = 'big-loading-message';

        const spinner = document.createElement('i');
        spinner.className = iconClassName('spinner');

        bigLoadingMessage.appendChild(spinner);
        bigLoadingMessage.appendChild(document.createTextNode(` ${translate('general', 'Loading')}...`));

        return bigLoadingMessage;
    }
    static _createSilentError() {
        const error = new Error();
        error.modalWasClosedWhileLoading = true;
        return error;
    }
}

export const unlessClosedBeforeFinishedLoading = (errorHandler) => {
    return (rejectionError) => {
        if (rejectionError.modalWasClosedWhileLoading !== true) {
            errorHandler(rejectionError);
        }
    };
};
