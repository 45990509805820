import ServerRenderedModal, {unlessClosedBeforeFinishedLoading} from 'components/Modals/ServerRenderedModal';

const liveBindServerModals = () => {
    document.querySelectorAll('[data-modal-url]').forEach(element => {
        element.addEventListener('click', event => {
            event.preventDefault();

            ServerRenderedModal.createAndLoad(element.dataset.modalUrl, {})
                .then(modal => modal.open())
                .catch(unlessClosedBeforeFinishedLoading(error => alert(error.message)));
        });
    });
};

export default liveBindServerModals;
