const MOVE_DISTANCE_FOR_DRAG_THRESHOLD = 10;

export default class {
    constructor(touch) {
        this._identifier = touch.identifier;
        this._target = touch.target;
        this._initialPageX = touch.pageX;
        this._initialPageY = touch.pageY;
        this._isDrag = false;
    }
    touchMoved(touchEvent) {
        if (! this._isDrag && this._getDistanceMoved(touchEvent) > MOVE_DISTANCE_FOR_DRAG_THRESHOLD) {
            this._isDrag = true;
        }
    }
    identifierEquals(identifier) {
        return this._identifier === identifier;
    }
    isDrag() {
        return this._isDrag;
    }
    getTarget() {
        return this._target;
    }
    _getDistanceMoved(touchEvent) {
        const xDistance = Math.abs(touchEvent.pageX - this._initialPageX);
        const yDistance = Math.abs(touchEvent.pageY - this._initialPageY);

        return Math.sqrt(xDistance * xDistance + yDistance * yDistance);
    }
}