export default (namespace, key, ...replacements) => {

    let translation = undefined;
    if (window.picqer && window.picqer.translations) {
        const translationSet = window.picqer.translations[namespace];

        if (translationSet !== undefined) {
            translation = translationSet[key];
        }
    }

    if (translation === undefined) {
        if (window.picqer.dev) {
            console.warn(`[${namespace}][${key}] not translated`);
        }
        if (window.Bugsnag !== undefined) {
            window.Bugsnag.notify({name: 'Missing translation', message: `[${namespace}][${key}]`});
        }

        translation = key;
    }

    replacements.forEach(replacement => {
        translation = translation.replace('%s', replacement);
    });

    return String(translation);
}