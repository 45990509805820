import FiltersFormBuilder from "./FiltersFormBuilder";
import QueryParam from "./QueryParam";
import TableFiltersOverlay from "./TableFiltersOverlay";

export default class {
    constructor() {
        window.picqer.tableFiltersManager = this; // Expose globally so page bundles can reference it without imports

        this._filtersContainer = null;
        this._onFiltersExpanded = null;
        this._onFiltersCollapsed = null;
    }
    onFiltersExpanded(handler) {
        this._onFiltersExpanded = handler;

        return this;
    }
    onFiltersCollapsed(handler) {
        this._onFiltersCollapsed = handler;

        return this;
    }
    bindEvents() {
        const contentElement = document.getElementById("content");

        if (!(contentElement instanceof window.HTMLElement)) {
            return;
        }

        contentElement.addEventListener("click", (clickEvent) => {
            this._expandFiltersIfFiltersButtonClicked(clickEvent);
            this._removeFilterAndReloadPageIfFilterClicked(clickEvent);
        });
    }
    areFiltersExpanded() {
        if (this._filtersContainer === null) {
            return false;
        }

        return this._filtersContainer.classList.contains("expanded");
    }
    _expandFiltersIfFiltersButtonClicked(clickEvent) {
        const filtersButton = clickEvent.target.closest(".expand-filters-button");
        if (filtersButton === null) {
            return;
        }

        clickEvent.preventDefault();

        this._filtersContainer = filtersButton.closest(".filters");
        this._expandFilters();
    }
    _removeFilterAndReloadPageIfFilterClicked(clickEvent) {
        const filterAnchor = clickEvent.target.closest("a.active-filter");
        if (filterAnchor === null) {
            return;
        }

        clickEvent.preventDefault();

        const currentQueryParams = this._getQueryParams();
        const queryParamsWithoutThisFilter = this._cloneQueryParamsExceptKeys(currentQueryParams, [
            filterAnchor.dataset.filterKey,
            "page",
        ]);

        window.location = this._rebuildCurrentUrlWithQueryParams(queryParamsWithoutThisFilter);
    }
    _expandFilters() {
        if (this.areFiltersExpanded()) {
            return;
        }

        this._filtersContainer.classList.add("expanded");

        new TableFiltersOverlay()
            .onHidden(this._filtersOverlayHidden.bind(this))
            .show(this._filtersContainer, this._getFormElement());

        if (typeof this._onFiltersExpanded === "function") {
            this._onFiltersExpanded();
        }
    }
    _filtersOverlayHidden() {
        this._filtersContainer.classList.remove("expanded");

        if (typeof this._onFiltersCollapsed === "function") {
            this._onFiltersCollapsed();
        }
    }
    _getFormElement() {
        if (this._filtersFormBuiler === undefined) {
            this._filtersFormBuiler = new FiltersFormBuilder(this._filtersContainer.dataset.filterType);
        }
        return this._filtersFormBuiler.buildForm();
    }
    _getQueryParams() {
        return window.location.search
            .replace("?", "")
            .split("&")
            .map((pair) => new QueryParam(pair));
    }
    _cloneQueryParamsExceptKeys(queryParamsToClone, keysToLeaveOut) {
        return queryParamsToClone
            .filter((queryParam) => !keysToLeaveOut.includes(queryParam.getKey()))
            .map((queryParam) => queryParam.clone());
    }
    _rebuildCurrentUrlWithQueryParams(queryParams) {
        if (queryParams.length === 0) {
            return window.location.pathname;
        }

        return `${window.location.pathname}?${queryParams.map((queryParam) => queryParam.joinPair()).join("&")}`;
    }
}
