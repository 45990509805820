import translate from './translate'

export default () => {
    window.picqer.isPageDirty = () => false; // Overridable in page code. The unload warning is shown if it returns boolean true.

    window.addEventListener('beforeunload', (e) => {
        if (window.picqer.isPageDirty() === true) {
            return (e.returnValue = translate('general', 'This page is asking you to confirm that you want to leave - data you have entered may not be saved.'));
        }

        // Don't show the unload warning. Browsers expect "nothing" to be returned (aka undefined).
        return undefined;
    });
}