import { unhandled } from "modules/ajax-utility";
import { get } from "modules/ajax-vanilla";
import { getPosition, isSelfOrDescendantOf, wrap } from "modules/dom";

class InfoBubble {
    constructor() {
        this.settings = {
            bubbleOffset: 13,
            delayBeforeRequest: 200,
            hideDelay: 450,
            minLeftMargin: 20,
            minRequestDuration: 150, // The sum of this and `delayBeforeRequest` is always the minimum waiting time before the bubble is shown (even if the data is already cached).
            minTopMargin: 20,
        };
    }

    // Hovering over the bubble keeps it open. Leaving the bubble or clicking outside it closes it.
    bindBubbleEvents() {
        if (this.options.trigger !== "click") {
            this.bubbleElement.addEventListener("mouseover", this.cancelHideDelay.bind(this));
            this.bubbleElement.addEventListener("mouseleave", this.startHideDelay.bind(this));
        }

        this.boundDocumentClickHandler = this.bodyClickHandler.bind(this);
        document.addEventListener("click", this.boundDocumentClickHandler);
    }

    bindToElement(triggerElement, entityIdAttributeName, dataUrlOrContentElement, options = {}) {
        this.boundDocumentClickHandler = null;
        this.bubbleElement = null;
        this.contentElement = null;
        this.dataUrl = null;
        this.delayPromiseReject = null;
        this.fetchingData = false;
        this.hidePromiseReject = null;
        this.markupPromise = null;
        this.options = options;
        this.triggerElement = triggerElement;
        this.triggerLeftDuringFetch = false;

        if (typeof dataUrlOrContentElement === "string" && typeof entityIdAttributeName === "string") {
            this.dataUrl = dataUrlOrContentElement.replace(
                "{id}",
                this.triggerElement.getAttribute(entityIdAttributeName)
            );
        } else if (typeof dataUrlOrContentElement === "string") {
            this.dataUrl = dataUrlOrContentElement;
        } else if (dataUrlOrContentElement instanceof window.HTMLElement) {
            this.contentElement = dataUrlOrContentElement;
        } else {
            throw Error("Invalid InfoBubble binding.");
        }

        if (options.trigger === "click") {
            this.triggerElement.addEventListener("click", (e) => {
                e.preventDefault();
                if (this.bubbleElement === null) {
                    e.stopPropagation();
                    this.fetchMarkup();
                }
            });
        } else {
            this.triggerElement.addEventListener("mouseover", this.mouseoverTrigger.bind(this));
            this.triggerElement.addEventListener("mouseleave", this.mouseleaveTrigger.bind(this));

            if (options.autoTriggerMouseOverAfterBind) {
                this.mouseoverTrigger();
            }
        }

        this.triggerElement.setAttribute("data-info-bubble-bound", "true");

        return this;
    }

    // Clicking outside the bubble closes it.
    bodyClickHandler(event) {
        if (!isSelfOrDescendantOf(event.target, this.bubbleElement)) {
            this.cancelHideDelay();
            this.hide();
        }
    }

    // Rejects the hide-promise, canceling hiding.
    cancelHideDelay() {
        if (this.hidePromiseReject !== null) {
            this.hidePromiseReject();
        }
    }

    // Fired after the delay, responsible for getting the html to display in the bubble (string or HTMLElement) and then showing the bubble.
    fetchMarkup() {
        this.fetchingData = true;

        const promises = [this.getMarkupPromise()];
        if (this.options.trigger !== "click") {
            promises.push(this.getMinDurationPromise());
        }

        Promise.all(promises)
            .then(([markupOrElement]) => {
                this.fetchingData = false;

                if (this.triggerLeftDuringFetch) {
                    this.triggerLeftDuringFetch = false;
                    return;
                }

                this.showBubble(markupOrElement);
            })
            .catch(
                unhandled(() => {
                    this.fetchingData = false;
                })
            );
    }

    // Fetches data or gets it from cache, returning a promise that resolves with said data.
    getMarkupPromise() {
        if (this.markupPromise === null) {
            if (this.dataUrl !== null) {
                this.markupPromise = get(this.dataUrl).catch((err) => {
                    throw err;
                });
            } else {
                this.markupPromise = Promise.resolve(this.contentElement);
            }
        }

        return this.markupPromise;
    }

    getDimensions() {
        return {
            trigger: {
                w: this.triggerElement.offsetWidth,
            },
            bubble: {
                h: this.bubbleElement.offsetHeight,
                w: this.bubbleElement.offsetWidth,
            },
        };
    }

    // Returns a promise that always resolves after a delay.
    getMinDurationPromise() {
        return new Promise((resolve) => {
            setTimeout(resolve, this.settings.minRequestDuration);
        });
    }

    getScrollLeft() {
        return window.scrollX || window.pageXOffset || document.documentElement.scrollLeft;
    }

    getScrollTop() {
        return window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
    }

    // Immediately hides the bubble and cleans up event listeners.
    hide() {
        this.hidePromiseReject = null;
        this.undoMoveToBelowTrigger();

        this.bubbleElement.parentNode.removeChild(this.bubbleElement);
        this.bubbleElement = null;

        document.removeEventListener("click", this.boundDocumentClickHandler);

        this.invoke(this.options.closed);
    }

    invoke(handler) {
        if (typeof handler === "function") {
            handler(this);
        }
    }

    // Invoked when the cursor leaves the trigger-element.
    mouseleaveTrigger() {
        if (this.delayPromiseReject !== null) {
            this.delayPromiseReject();
        }
        if (this.fetchingData) {
            this.triggerLeftDuringFetch = true;
        }
        if (this.bubbleElement !== null && this.hidePromiseReject === null) {
            this.startHideDelay();
        }
    }

    // Invoked when the cursor enters the trigger-element.
    mouseoverTrigger() {
        if (this.bubbleElement === null) {
            const delayPromise = new Promise((resolve, reject) => {
                setTimeout(resolve, this.settings.delayBeforeRequest);
                this.delayPromiseReject = reject;
            });

            delayPromise.then(this.fetchMarkup.bind(this)).catch(() => {
                this.delayPromiseReject = null;
            });
        }

        this.cancelHideDelay();
    }

    // For triggers high on the page, the upper part of the bubble may be (nearly) off-screen. In this case we move the bubble to below the trigger so it's still entirely visible. The tip (arrow) is positioned accordingly.
    moveToBelowTrigger() {
        this.bubbleElement.style.marginTop = `${this.triggerElement.offsetHeight + this.settings.bubbleOffset}px`;
        this.bubbleElement.classList.add("below-trigger");
    }

    // Triggers near the left edge of the page may have their bubbles partially off-screen (x < 0). This method corrects this and maintains proper tip position.
    shiftRightIntoView() {
        const shiftDistance = this.settings.minLeftMargin - this.bubbleElement.offsetLeft;
        const currentMarginLeft = parseFloat(this.bubbleElement.style.marginLeft);

        this.bubbleElement.style.marginLeft = `${currentMarginLeft + shiftDistance}px`;

        const tipElement = this.bubbleElement.querySelector(".info-bubble-tip");
        if (tipElement) {
            const relativeWrapper = wrap(tipElement, "div");
            relativeWrapper.className = "info-bubble-tip-shift-inner-wrapper";
            relativeWrapper.style.marginLeft = `${-shiftDistance}px`;

            wrap(relativeWrapper, "div").className = "info-bubble-tip-shift-outer-wrapper";
        }
    }

    // Called once markup is fetched and minimum delay resolves. Shows the bubble.
    showBubble(markupOrElement) {
        const triggerElementPosition = getPosition(this.triggerElement);

        if (typeof markupOrElement === "string") {
            let tempWrapperDiv = document.createElement("div");
            tempWrapperDiv.insertAdjacentHTML("afterbegin", markupOrElement);
            this.bubbleElement = tempWrapperDiv.querySelector(".info-bubble");
        } else if (markupOrElement instanceof window.HTMLElement) {
            this.bubbleElement = markupOrElement;
        }
        this.bubbleElement.style.left = `${triggerElementPosition.left}px`;
        this.bubbleElement.style.top = `${triggerElementPosition.top}px`;

        document.body.appendChild(this.bubbleElement);

        const size = this.getDimensions();

        // To center the bubble horizontally in relation to the trigger, move it left half the bubble-width, then right half the trigger-width.
        this.bubbleElement.style.marginLeft = `${size.bubble.w / -2 + size.trigger.w / 2}px`;
        this.bubbleElement.style.marginTop = `${-size.bubble.h - this.settings.bubbleOffset}px`;

        // The bubble is now positioned above the trigger, with the tip coming out the bottom, centered on both the bubble and the trigger.

        if (this.bubbleElement.offsetLeft < this.getScrollLeft() + this.settings.minLeftMargin) {
            this.shiftRightIntoView();
        }

        if (this.bubbleElement.offsetTop < this.getScrollTop() + this.settings.minTopMargin) {
            this.moveToBelowTrigger();
        }

        this.bubbleElement.classList.remove("invisible");
        this.bindBubbleEvents();

        this.invoke(this.options.opened);
    }

    // Creates a new promise but does not return it. Instead, the reject handler is exposed. Invoke it to cancel hiding.
    startHideDelay() {
        new Promise((resolve, reject) => {
            setTimeout(resolve, this.settings.hideDelay);
            this.hidePromiseReject = reject;
        })
            .then(this.hide.bind(this))
            .catch(() => {
                this.hidePromiseReject = null;
            });
    }

    undoMoveToBelowTrigger() {
        this.bubbleElement.style.marginTop = "";
        this.bubbleElement.classList.remove("below-trigger");
    }
}

// This utility class creates the basic InfoBubble DOM elements.
// Pass the result as the 2nd argument to the default exported function (`dataUrlOrContentElement`)
export class InfoBubbleElementCreator {
    constructor() {
        this.elements = {
            content: this.createDivWithClass("info-bubble-inner"),
            wrapper: this.createDivWithClass("info-bubble invisible auto-width"),
        };

        this.appendContent(this.createDivWithClass("info-bubble-tip"));
        this.elements.wrapper.appendChild(this.elements.content);
    }

    appendContent(element) {
        this.elements.content.appendChild(element);
    }

    createDivWithClass(className) {
        const div = document.createElement("div");
        div.className = className;
        return div;
    }
}

export default (attributeNameOrTriggerElement, dataUrlOrContentElement, options) => {
    // If `attributeNameOrTriggerElement` is a string, find elements with that attribute and create bubble for each. The value of the attribute must be the entity id.
    // If `dataUrlOrContentElement` is a string (url), it must contain "{id}" placeholder.

    if (typeof attributeNameOrTriggerElement === "string" && typeof dataUrlOrContentElement === "string") {
        return Array.from(
            document.querySelectorAll(`[${attributeNameOrTriggerElement}]:not([data-info-bubble-bound])`)
        ).map((triggerElement) => {
            return new InfoBubble().bindToElement(
                triggerElement,
                attributeNameOrTriggerElement,
                dataUrlOrContentElement,
                options
            );
        });
    }

    if (attributeNameOrTriggerElement instanceof window.HTMLElement) {
        if (dataUrlOrContentElement instanceof InfoBubbleElementCreator) {
            dataUrlOrContentElement = dataUrlOrContentElement.elements.wrapper;
        }
        return new InfoBubble().bindToElement(attributeNameOrTriggerElement, null, dataUrlOrContentElement, options);
    }

    return null;
};
