import applyDatePicker from 'modules/applyDatePicker';
import AbstractFilter from './AbstractFilter';

export default class extends AbstractFilter {
    _createFormControlsChild() { // Implementation of abstract method
        const input = document.createElement('input');
        input.className = 'form-control';
        input.id = `field-${this._getDefinition().key}`;
        input.name = this.getFieldName();
        input.type = 'text';

        if (typeof this._getInitialValue() === 'string') {
            input.value = this._getInitialValue();
        }

        applyDatePicker(input);

        return input;
    }
}