class AssetLoader {
    constructor(tagName, location) {
        this._element = document.createElement(tagName);
        this._assetLocation = this._buildAssetLocation(location);
        this._assetLoaderResultHandlers = null;
    }

    // PROTECTED:
    _insertElementWithProperties(properties) {
        for (let [property, value] of Object.entries(properties)) {
            this._element[property] = value;
        }

        document.head.appendChild(this._element);
    }
    _getAssetLocation() {
        return this._assetLocation;
    }
    _getSuccessHandler() {
        return this._getAssetLoaderResultHandlers().successHandler;
    }
    _getFailureHandler() {
        return this._getAssetLoaderResultHandlers().failureHandler;
    }
    _getAssetLoaderPromise() {
        return this._getAssetLoaderResultHandlers().promise;
    }

    // PRIVATE:
    _buildAssetLocation(location) {
        if (this._isLocationExternal(location)) {
            return location;
        }

        return window.picqer.assetsPrefix + location;
    }
    _isLocationExternal(location) {
        return ["https://", "http://", "//"].some((protocol) => location.substr(0, protocol.length) === protocol);
    }
    _getAssetLoaderResultHandlers() {
        if (this._assetLoaderResultHandlers === null) {
            const handlers = {};
            handlers.promise = new Promise((resolve, reject) => {
                handlers.successHandler = resolve;
                handlers.failureHandler = () => reject(`Error loading ${this._assetLocation}`);
            });

            this._assetLoaderResultHandlers = handlers;
        }

        return this._assetLoaderResultHandlers;
    }
}

class ScriptLoader extends AssetLoader {
    constructor(location) {
        super("script", location);
    }
    load() {
        this._insertElementWithProperties({
            src: this._getAssetLocation(),
            onload: this._getSuccessHandler(),
            onerror: this._getFailureHandler(),
            type: "text/javascript",
            async: true,
        });

        return this._getAssetLoaderPromise();
    }
}

class StyleSheetLoader extends AssetLoader {
    constructor(location) {
        super("link", location);
    }
    load() {
        this._insertElementWithProperties({
            href: this._getAssetLocation(),
            onload: this._getSuccessHandler(),
            onerror: this._getFailureHandler(),
            rel: "stylesheet",
            type: "text/css",
        });

        return this._getAssetLoaderPromise();
    }
}

export const loadScript = (location) => new ScriptLoader(location).load();
export const loadVueScript = () => new ScriptLoader(`/scripts/vue${window.picqer.dev ? "" : ".min"}.js`).load();
export const loadStyleSheet = (location) => new StyleSheetLoader(location).load();
