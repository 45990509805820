const MOBILE_MENU_HIDDEN_CLASS = "mobile-menu-hidden";

export default class {
    constructor() {
        this._triggerButton = document.querySelector(".menu-button");
        this._menu = document.getElementById("menu");
    }
    bindEvents() {
        if (!(this._triggerButton instanceof window.HTMLElement)) {
            return;
        }

        this._triggerButton.addEventListener("click", (e) => {
            e.preventDefault();

            if (this._isMobileMenuHidden()) {
                this._showMobileMenu();
            } else {
                this._hideMobileMenu();
            }
        });
    }
    _isMobileMenuHidden() {
        return this._menu.classList.contains(MOBILE_MENU_HIDDEN_CLASS);
    }
    _showMobileMenu() {
        this._menu.classList.remove(MOBILE_MENU_HIDDEN_CLASS);
    }
    _hideMobileMenu() {
        this._menu.classList.add(MOBILE_MENU_HIDDEN_CLASS);
    }
}
