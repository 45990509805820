import throttle from 'lodash/throttle';
import {iconHtml} from 'modules/icon';

const recalculate = (img, container) => {

  // Reset dimensions for calculations:
  container.style.width = '100%';
  img.style.height = 'auto';
  img.style.width = 'auto';

  const imageHeight = img.offsetHeight;
  const imageWidth = img.offsetWidth;

  const availableHeight = container.offsetHeight;
  const availableWidth = container.offsetWidth;

  // Scale is how much of the available space is actually in use by the image. We want the greatest value (x or y).
  const scaleFactor = Math.max(imageHeight / availableHeight, imageWidth / availableWidth);

  // Scale in both directions so the maximum available space is used without distorting the image.
  const scaledHeight = scaleFactor > 1 ? imageHeight / scaleFactor : imageHeight;
  const scaledWidth = scaleFactor > 1 ? imageWidth / scaleFactor : imageWidth;

  img.style.height = `${scaledHeight}px`;
  img.style.width = `${scaledWidth}px`;

  // Make container use natural size based on the img it contains. This centers it horizontally.
  container.style.width = 'auto';
};

const keydownHandler = (event) => {
  if (event.which === 27) {
    // Simulate click on backdrop to close the modal (and unbind the event listeners)
    $('div.image-modal').trigger('click');
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function() {
  $(document.body).on('click', '.image-modal-trigger', function(event) {
    event.preventDefault();

    if (event.currentTarget.tagName !== 'A' || !event.currentTarget.href) {
      // eslint-disable-next-line no-console
      console.warn('Invalid selector for ImageModal, must be <a> with href');
      return;
    }

    const $imageModal = $('<div class="image-modal"></div>').appendTo(document.body);
    const $inner = $('<div class="image-modal-inner"></div>').appendTo($imageModal);
    const $centeredWrapper = $('<div class="image-modal-centered-wrapper"></div>').appendTo($inner);

    // eslint-disable-next-line @babel/no-invalid-this
    const $img = $(`<img src="${this.href}">`).appendTo($centeredWrapper);
    $(`<button type="button" class="btn btn-flat btn-lg btn-square close">${iconHtml('times-circle')}</button>`).appendTo($centeredWrapper);

    function imageLoadedOrFailed() {
      // eslint-disable-next-line @babel/no-invalid-this
      recalculate(this, $inner[0]);

      // Image-wrapper is initially invisible to prevent flash of content. Can safely remain visible after first calculation
      $centeredWrapper.addClass('visible');

      // eslint-disable-next-line @babel/no-invalid-this
      const throttledRecalculate = throttle(recalculate.bind(null, this, $inner[0]), 200);

      window.addEventListener('resize', throttledRecalculate);
      document.body.addEventListener('keydown', keydownHandler);

      // eslint-disable-next-line no-shadow,@shopify/prefer-early-return
      $imageModal.on('click', (event) => {
        if (event.target !== $img[0]) {
          window.removeEventListener('resize', throttledRecalculate);
          document.body.removeEventListener('keydown', keydownHandler);
          $imageModal.remove();
        }
      });
    }

    // eslint-disable-next-line @babel/no-invalid-this
    $img.on('error', imageLoadedOrFailed.bind(this));

    // eslint-disable-next-line @babel/no-invalid-this
    $img.on('load', imageLoadedOrFailed.bind(this));
  });
}
