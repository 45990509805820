import translate from "./translate";

const KEY_ENTER = 13;
const KEY_ESCAPE = 27;

class Message {
    constructor() {
        // The container element:
        this.messageElement = document.createElement("div");
        this.messageElement.className = "modal-alert-message";

        // The actual text:
        this.textElement = document.createElement("div");
        this.textElement.className = "modal-alert-text";
        this.messageElement.appendChild(this.textElement);

        // The footer that parents the button(s):
        this.footerElement = document.createElement("div");
        this.footerElement.className = "modal-alert-footer";
        this.messageElement.appendChild(this.footerElement);

        this.keyboardHandlers = [];
        this.areButtonsDisabled = false;
    }

    addButton(buttonText, handler, keyboardKeys, styleClassName = "btn-default") {
        const button = document.createElement("button");
        button.className = `btn ${styleClassName}`;
        button.innerText = buttonText;
        button.type = "button";
        button.addEventListener("click", () => {
            if (!this.areButtonsDisabled) {
                this.disableButtons();
                handler();
            }
        });

        this.footerElement.insertAdjacentHTML("beforeend", "&nbsp;");
        this.footerElement.appendChild(button);

        this.keyboardHandlers.push({
            handler,
            keys: keyboardKeys,
        });

        return this;
    }

    appendTo(target) {
        target.appendChild(this.messageElement);

        return this;
    }

    destroy() {
        this.messageElement.parentNode.removeChild(this.messageElement);
    }

    disableButtons() {
        this.areButtonsDisabled = true;
        Array.from(this.footerElement.querySelectorAll("button")).forEach((button) => (button.disabled = true));
    }

    keyPressed(keyCode) {
        if (!this.areButtonsDisabled) {
            const keyboardHandler = this.keyboardHandlers.find(({ keys }) => keys.includes(keyCode)) || null;

            if (keyboardHandler !== null) {
                this.disableButtons();
                keyboardHandler.handler();
            }
        }
    }

    setText(text) {
        this.textElement.innerText = text;

        return this;
    }

    stealFocus() {
        if (document.activeElement !== null) {
            document.activeElement.blur();
        }

        return this;
    }
}

const privateAlertManager = new (class {
    constructor() {
        this.backdropElement = null;
        this.message = null;
        this.previousBodyOverflowY = null;
        this.previousBodyPaddingRight = null;
        this.isKeyboardBound = false;
        this.isKeyboardCaptured = false;
    }

    captureKeyboard() {
        this.isKeyboardCaptured = true;

        if (!this.isKeyboardBound) {
            this.isKeyboardBound = true;

            document.addEventListener("keydown", (e) => {
                if (this.isKeyboardCaptured && this.message !== null) {
                    e.preventDefault();
                    this.message.keyPressed(e.which);
                }
            });
        }
    }

    hide() {
        document.body.removeChild(this.backdropElement);
        document.body.style.overflowY = this.previousBodyOverflowY;
        document.body.style.paddingRight = this.previousBodyPaddingRight;

        if (this.message !== null) {
            this.message.destroy();
        }

        this.releaseKeyboard();
    }

    hideBodyScrollbar() {
        this.previousBodyOverflowY = document.body.style.overflowY;

        const bodyWidthBefore = document.body.offsetWidth;
        document.body.style.overflowY = "hidden";
        const bodyWidthAfter = document.body.offsetWidth;

        const bodyWidthDifference = bodyWidthAfter - bodyWidthBefore;

        if (bodyWidthDifference > 0) {
            this.previousBodyPaddingRight = document.body.style.paddingRight;
            document.body.style.paddingRight = `${bodyWidthDifference}px`;
        }
    }

    overrideNativeAlert() {
        const defaultHideText = this.trans("OK");

        window.alert = function (messageText = "", hideText = defaultHideText) {
            if (typeof hideText !== "string") {
                hideText = defaultHideText;
            }

            return new Promise((resolve) => {
                this.hideBodyScrollbar();
                this.showBackdrop();
                this.captureKeyboard();

                this.message = new Message()
                    .addButton(hideText, resolve, [KEY_ENTER, KEY_ESCAPE], "btn-primary")
                    .setText(messageText)
                    .appendTo(this.backdropElement)
                    .stealFocus();
            }).then(this.hide.bind(this));
        }.bind(this);
    }

    overrideNativeConfirm() {
        const defaultConfirmText = this.trans("OK");
        const defaultDenyText = this.trans("Cancel");

        window.confirm = function (
            messageText = "",
            confirmText = defaultConfirmText,
            denyButtonText = defaultDenyText
        ) {
            return new Promise((resolve) => {
                const resolveWithFalse = () => resolve(false);
                const resolveWithTrue = () => resolve(true);

                this.hideBodyScrollbar();
                this.showBackdrop();
                this.captureKeyboard();

                this.message = new Message()
                    .addButton(denyButtonText, resolveWithFalse, [KEY_ESCAPE])
                    .addButton(confirmText, resolveWithTrue, [KEY_ENTER], "btn-primary")
                    .setText(messageText)
                    .appendTo(this.backdropElement)
                    .stealFocus();
            }).then((didConfirm) => {
                this.hide();
                return didConfirm;
            });
        }.bind(this);
    }

    releaseKeyboard() {
        this.isKeyboardCaptured = false;
    }

    showBackdrop() {
        if (this.backdropElement === null) {
            this.backdropElement = document.createElement("div");
            this.backdropElement.className = "modal-alert";
        }

        this.backdropElement.classList.add("visible");
        this.backdropElement.style.top = `${window.scrollY || document.documentElement.scrollTop}px`;
        document.body.appendChild(this.backdropElement);
    }

    trans(key) {
        try {
            return translate("modal_alert", key);
        } catch (error) {
            return key;
        }
    }
})();

export const overrideNativeAlert = privateAlertManager.overrideNativeAlert.bind(privateAlertManager);
export const overrideNativeConfirm = privateAlertManager.overrideNativeConfirm.bind(privateAlertManager);
