export default class {
    constructor(rootElement, persistInDomOnClose = false) {
        this._rootElement = rootElement;
        this._modalElement = null;
        this._isModalOpen = false;
        this._onClosed = null;
        this._modalBackdrop = null;
        this._keepBackdropOnClose = false;

        this._bindCloseButton();
        this.close = this.close.bind(this);

        const modalExistsInDOM = document.body.contains(this.getModalElement());
        this._removeModalFromDomOnClose = ! modalExistsInDOM && ! persistInDomOnClose;
        if (! modalExistsInDOM) {
            this._insertNewlyCreatedModalInDOM(this._rootElement);
        }
    }
    open() {
        this._isModalOpen = true;
        this._rootElement.classList.remove('hidden');

        if (this._modalBackdrop === null) {
            this._modalBackdrop = this._insertModalBackdropInDOM();
        }
        this._closeModalOnClick(this._modalBackdrop);
        this._closeModalOnEscape();

        this._disableBodyScrolling();
        this._focusDocumentBody();
    }
    close() {
        if (! this._isModalOpen) {
            return;
        }

        this._isModalOpen = false;

        if (this._removeModalFromDomOnClose) {
            this._removeElementFromDOM(this._rootElement);
        } else {
            this._hideModalUntilOpened(this._rootElement);
        }

        if (this._keepBackdropOnClose) {
            this._unbindCloseModalOnClickBackdrop();
        } else {
            this._removeElementFromDOM(this._modalBackdrop);
            this._modalBackdrop = null;
        }

        this._enableBodyScrolling();

        if (typeof this._onClosed === 'function') {
            this._onClosed();
        }
    }
    onClosed(handler) {
        this._onClosed = handler;
        return this;
    }
    markBackdropForRecycling() {
        this._keepBackdropOnClose = true;
    }
    recycleExistingBackdrop(backdrop) {
        if (! document.body.contains(backdrop)) {
            throw new Error('Backdrop no longer in DOM.');
        }
        this._modalBackdrop = backdrop;
        return this;
    }

    // PROTECTED
    getModalClassName() {
        return 'Modal';
    }
    getModalElement() {
        if (this._modalElement === null) {
            if (this.getModalClassName() === 'FormModal') {
                this._modalElement = this._rootElement.querySelector('.picqer-modal') || undefined;
            } else {
                this._modalElement = this._rootElement;
            }
        }

        return this._modalElement;
    }
    getModalBackdrop() {
        if (! document.body.contains(this._modalBackdrop)) {
            return null;
        }
        return this._modalBackdrop;
    }
    isModalOpen() {
        return this._isModalOpen;
    }

    // PRIVATE
    _bindCloseButton() {
        if (this.getModalElement().dataset.isCloseButtonLiveBound === 'true') {
            return;
        }

        this.getModalElement().dataset.isCloseButtonLiveBound = 'true';

        this.getModalElement().addEventListener('click', (e) => {
            if (e.target.matches('.picqer-modal .close-modal')) {
                this.close();
            }
        });
    }
    _insertNewlyCreatedModalInDOM(rootElement) {
        this._hideModalUntilOpened(rootElement);
        document.body.appendChild(rootElement);
    }
    _hideModalUntilOpened(rootElement) {
        rootElement.classList.add('hidden');
    }
    _insertModalBackdropInDOM() {
        const backdrop = document.createElement('div');
        backdrop.className = 'picqer-modal-backdrop';
        document.body.appendChild(backdrop);

        return backdrop;
    }
    _closeModalOnClick(element) {
        if (! (element instanceof window.HTMLElement)) {
            return;
        }
        element.addEventListener('click', this.close);
    }
    _closeModalOnEscape() {
        const closeModal = (event) => {
            if (event.which === 27) {
                document.body.removeEventListener('keydown', closeModal);
                this.close();
            }
        }

        document.body.addEventListener('keydown', closeModal);
    }
    _unbindCloseModalOnClickBackdrop() {
        const modalBackdrop = this.getModalBackdrop();
        if (modalBackdrop === null) {
            return;
        }
        modalBackdrop.removeEventListener('click', this.close);
    }
    _removeElementFromDOM(element) {
        if (! (element instanceof window.HTMLElement) || ! document.body.contains(element)) {
            return;
        }

        element.parentElement.removeChild(element);
    }
    _disableBodyScrolling() {
        const openModalsCount = parseInt(document.body.dataset.openModalsCount || '0', 10) + 1;
        document.body.dataset.openModalsCount = openModalsCount.toString();

        document.body.classList.add('picqer-modal-open');
    }
    _enableBodyScrolling() {
        const openModalsCount = parseInt(document.body.dataset.openModalsCount || '0', 10) - 1;
        document.body.dataset.openModalsCount = openModalsCount.toString();

        if (openModalsCount === 0) {
            document.body.classList.remove('picqer-modal-open');
        }
    }
    _focusDocumentBody() {
        if (document.activeElement instanceof window.HTMLElement) {
            document.activeElement.blur();
        }
    }
}