const KEY_ENTER = 13;

const privateScanDetection = new (class {
    constructor() {
        this._activeElementBeforeEnter = null;
        this._inputSequence = "";
        this._valueBefore = "";
        this._lastKeyPressTime = 0;
        this._onScanDetected = null;

        document.addEventListener("keydown", this._documentKeyDown.bind(this), { capture: true });
        document.addEventListener("keypress", this._documentKeyPressed.bind(this));
    }

    onScanDetected(handler) {
        this._onScanDetected = handler;
    }

    _appendToInputSequence(charCode) {
        this._inputSequence += this._charCodeToString(charCode);
    }

    _charCodeToString(charCode) {
        return String.fromCharCode(charCode).replace(/\r?\n|\r/gm, "");
    }

    _deferToPreventIEQuirks(handler) {
        // IE+Edge "click" a button (even with type=button) when it has focus and enter is pressed. The timeout prevents this.
        setTimeout(handler, 0);
    }

    _documentKeyDown(event) {
        if (!this._keyPressWillSubmitInputSequence(event.which)) {
            return;
        }

        this._preventUnwantedSideEffectsFromPressingEnter();

        if (!this._elementHasScannerThreshold) {
            return;
        }

        if (this._inputSequence.length <= 2) {
            this._inputSequence = "";

            return;
        }

        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        // Because the default of the `keydown` event is prevented, we need
        // to submit the input sequence in the `keydown` handler. The
        // `_documentKeyPressed` handler will _not_ be called.
        this._submitInputSequence();
        this._resetValueInInputToBeforeValue(this._getActiveElementDuringScan());
    }

    _documentKeyPressed({ which: keyCode }) {
        if (this._keyPressWillSubmitInputSequence(keyCode)) {
            this._submitInputSequence();
            this._resetValueInInputToBeforeValue(this._getActiveElementDuringScan());
        } else if (this._keyStrokeIsFastEnoughForScan()) {
            this._appendToInputSequence(keyCode);
        } else {
            this._captureBeforeValue();
            this._startNewInputSequence(keyCode);
        }

        this._lastKeyPressTime = Date.now();
    }

    _captureBeforeValue() {
        const activeElement = this._getActiveElementDuringScan();
        if (!this._getActiveElementDuringScan().hasAttribute("data-scanner-rollback")) {
            return;
        }

        this._valueBefore = activeElement.value;
    }

    _resetValueInInputToBeforeValue(element) {
        if (!element.hasAttribute("data-scanner-rollback")) {
            return;
        }

        element.value = this._valueBefore;
        element.dispatchEvent(new Event("input"));
        element.dispatchEvent(new Event("change"));
    }

    _elementRequiresFocusDuringKeyPress(element) {
        if (!(element instanceof window.HTMLElement)) {
            return false;
        }
        return element.tagName === "INPUT" && !this._isElementRadioButtonOrCheckbox(element);
    }

    _focusDocumentBody() {
        if (document.activeElement instanceof window.HTMLElement) {
            document.activeElement.blur();
        }
    }

    _getActiveElementDuringScan() {
        if (this._activeElementBeforeEnter instanceof window.HTMLElement) {
            return this._activeElementBeforeEnter;
        }

        return document.activeElement;
    }

    _inputSequenceHasValue() {
        return this._inputSequence.length > 0;
    }

    _isElementRadioButtonOrCheckbox(element) {
        return element.getAttribute("type") === "radio" || element.getAttribute("type") === "checkbox";
    }

    _isScanEventPreventedByElement(element) {
        // Is the element a key-sensitive form field? Then the barcode event is not fired. The data-attribute can override this default behavior for specific form fields to fire the event regardless of focus.
        return (
            !element.hasAttribute("data-scanner-allow-event") &&
            ((element.tagName === "INPUT" && !this._isElementRadioButtonOrCheckbox(element)) ||
                element.tagName === "SELECT" ||
                element.tagName === "TEXTAREA")
        );
    }

    _keyPressWillSubmitInputSequence(keyCode) {
        return keyCode === KEY_ENTER && this._inputSequenceHasValue() && this._keyStrokeIsFastEnoughForScan();
    }

    _keyStrokeIsFastEnoughForScan() {
        const nowTime = Date.now();
        const isFastEnoughForScan = nowTime - this._lastKeyPressTime < 150;

        return isFastEnoughForScan;
    }

    _preventUnwantedSideEffectsFromPressingEnter() {
        if (!this._elementRequiresFocusDuringKeyPress(document.activeElement)) {
            this._activeElementBeforeEnter = document.activeElement;
            this._focusDocumentBody();
        }
    }

    _restoreOriginalFocus() {
        if (this._activeElementBeforeEnter instanceof window.HTMLElement) {
            this._activeElementBeforeEnter.focus();
            this._activeElementBeforeEnter = null;
        }
    }

    _scanDetected(scannedValue, activeElement) {
        // Does not invoke onScanDetected if a key-sensitive form field was focused during the scan
        if (typeof this._onScanDetected === "function") {
            const activeElementPreventsScanEvent =
                activeElement instanceof window.HTMLElement && this._isScanEventPreventedByElement(activeElement);
            if (!activeElementPreventsScanEvent && document.querySelector(".modal.in") === null) {
                this._onScanDetected(scannedValue, activeElement);
            }
        }
    }

    _startNewInputSequence(charCode) {
        this._inputSequence = this._charCodeToString(charCode);
    }

    _submitInputSequence() {
        this._scanDetected(this._inputSequence, this._getActiveElementDuringScan());
        this._inputSequence = "";
        this._deferToPreventIEQuirks(() => this._restoreOriginalFocus());
    }

    get _elementHasScannerThreshold() {
        return this._getActiveElementDuringScan()?.hasAttribute("data-scanner-threshold") ?? false;
    }
})();

class Scan {
    constructor(scannedValue, activeElement) {
        this._scannedValue = scannedValue;
        this._activeElement = activeElement;
        this._genericHandlerPrevented = false;

        this._boundHandlers = null;
        this._genericHandler = null;
    }

    invokeBoundHandlers() {
        this._getBoundHandlers().forEach((handler) => {
            const handlerReturnValue = handler(
                this._scannedValue,
                this._activeElement,
                this._genericHandlerWillBeInvoked()
            );
            this._processBoundHandlerReturnValue(handlerReturnValue);
        });

        return this;
    }

    invokeGenericHandler() {
        if (this._genericHandlerWillBeInvoked() && !this._genericHandlerPrevented) {
            this._getGenericHandler()();
        }

        return this;
    }

    _processBoundHandlerReturnValue(handlerReturnValue) {
        if (typeof handlerReturnValue !== "object" || handlerReturnValue === null) {
            return;
        }

        if (handlerReturnValue.preventGenericHandler === true) {
            this._genericHandlerPrevented = true;
        }
    }

    _genericHandlerWillBeInvoked() {
        return typeof this._getGenericHandler() === "function";
    }

    _getBoundHandlers() {
        if (this._boundHandlers === null) {
            this._boundHandlers = [];
            if (Array.isArray(window.picqer.onBarcodeScanned.bindings)) {
                window.picqer.onBarcodeScanned.bindings.forEach((binding) => {
                    if (!this._genericHandlerWillBeInvoked() || binding.invokeRegardlessOfGenericHandling) {
                        this._boundHandlers.push(binding.handler);
                    }
                });
            }
        }

        return this._boundHandlers;
    }

    _getGenericHandler() {
        if (this._genericHandler === null) {
            const firstThreeChars = this._scannedValue.substr(0, 3).toUpperCase();
            const entityId = parseInt(this._scannedValue.substr(3), 10);

            switch (firstThreeChars) {
                case "PP#":
                    this._genericHandler = () => (window.location.href = `/picklists/${entityId}`);
                    break;
                case "PB#":
                    this._genericHandler = () => (window.location.href = `/picklists/batches/${entityId}`);
                    break;
                case "PU#":
                    this._genericHandler = () => (window.location.href = `/purchaseorders/${entityId}`);
                    break;
                case "PC#":
                    this._genericHandler = () => (window.location.href = `/picking-containers/${entityId}`);
                    break;
                case "PR#":
                    this._genericHandler = () => (window.location.href = `/returns/${entityId}`);
                    break;
                case "PL#":
                    this._genericHandler = () => (window.location.href = `/locations/${entityId}`);
                    break;
                default:
                    this._genericHandler = false;
            }
        }

        return this._genericHandler;
    }
}

export default () => {
    window.picqer.onBarcodeScanned = (handler, options) => {
        window.picqer.onBarcodeScanned.bindings = window.picqer.onBarcodeScanned.bindings || [];
        window.picqer.onBarcodeScanned.bindings.push(Object.assign({}, options, { handler }));

        return () => {
            window.picqer.onBarcodeScanned.bindings = window.picqer.onBarcodeScanned.bindings.filter((binding) => {
                return binding.handler !== handler;
            });
        };
    };

    window.dispatchBarcode = (barcode) => {
        privateScanDetection._inputSequence = barcode;
        privateScanDetection._submitInputSequence();
    };

    privateScanDetection.onScanDetected((scannedValue, activeElement) => {
        new Scan(scannedValue, activeElement).invokeBoundHandlers().invokeGenericHandler();
    });
};
