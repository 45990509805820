const HTTP_FORBIDDEN = 403;
const HTTP_SERVICE_UNAVAILABLE = 503;
const RETRY_DELAY_IN_MS = 3000;

let nextRequestIndex = 0;

class Request {
    constructor(url, requestData = null, method = "GET", retryIfServiceUnavailable = true) {
        this._url = url;
        this._requestData = requestData;
        this._method = method;
        this._retryIfServiceUnavailable = retryIfServiceUnavailable;
        this._requestIndex = nextRequestIndex++;

        this._isRetry = false;
        this._resolve = null;
        this._reject = null;
        this._sentTimestamp = null;
    }

    send() {
        this._sentTimestamp = Date.now();

        return new Promise((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;

            if (this._method === "GET") {
                this._sendRequestWithDataAsQueryString();
            } else {
                this._sendRequestWithDataInBody();
            }
        });
    }

    _sendRequestWithDataAsQueryString() {
        const url = this._url + this._getRequestDataAsQueryString();

        const xmlHttpRequest = this._createXMLHttpRequest(this._method, url);
        xmlHttpRequest.send();
    }

    _sendRequestWithDataInBody() {
        const xmlHttpRequest = this._createXMLHttpRequest(this._method, this._url);

        let xmlHttpRequestData = this._requestData;

        if (!(xmlHttpRequestData instanceof window.FormData)) {
            xmlHttpRequest.setRequestHeader("Content-Type", "application/json");
            xmlHttpRequestData = JSON.stringify(xmlHttpRequestData);
        }

        xmlHttpRequest.send(xmlHttpRequestData);
    }

    _createXMLHttpRequest(method, url) {
        const xmlHttpRequest = new window.XMLHttpRequest();
        xmlHttpRequest.open(method, url, true);

        xmlHttpRequest.setRequestHeader("X-Requested-With", "XMLHttpRequest");

        let token = document.querySelector('meta[name="csrf-token"]');
        if (token) {
            xmlHttpRequest.setRequestHeader("x-csrf-token", token.content);
        }

        xmlHttpRequest.onload = this._onRequestCompleted.bind(this, xmlHttpRequest);
        xmlHttpRequest.onerror = this._onRequestFailed.bind(this, xmlHttpRequest);

        return xmlHttpRequest;
    }

    _getRequestDataAsQueryString() {
        if (!this._objectHasValue(this._requestData)) {
            return "";
        }

        const requestDataAttributes = Object.entries(this._requestData).map(([key, value]) => {
            return `${key}=${window.encodeURIComponent(value)}`;
        });

        return `?${requestDataAttributes.join("&")}`;
    }

    _onRequestCompleted(xmlHttpRequest) {
        const { status, responseText, contentType } = this._getResponseProperties(xmlHttpRequest);

        const isRequestSuccessful = status >= 200 && status < 400;
        if (!isRequestSuccessful) {
            this._handleErrorResponse(xmlHttpRequest);
            return;
        }

        if (contentType === "application/json") {
            this._resolve(JSON.parse(responseText));
            return;
        }

        this._resolve(responseText);
    }

    _onRequestFailed() {
        this._reject({ reason: "transaction error" });
    }

    _handleErrorResponse(xmlHttpRequest) {
        const { status, responseText, contentType, forbiddenReason } = this._getResponseProperties(xmlHttpRequest);

        if (status === HTTP_SERVICE_UNAVAILABLE && this._retryIfServiceUnavailable) {
            if (!this._isRetry) {
                this._retryRequest();
                return;
            }
        }

        if (status === HTTP_FORBIDDEN && forbiddenReason === "Not logged in") {
            this._reject({ reason: "unauthenticated", handled: true });
            this._redirectToLogin();
            return;
        }

        if (contentType === "application/json") {
            this._reject({ json: JSON.parse(responseText), status });
            return;
        }

        this._reject({ responseText, status });
    }

    _retryRequest() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const originalRequest = this;
        const retryRequest = this._copyRequestForRetry();

        setTimeout(() => {
            retryRequest.send().then(this._resolve.bind(originalRequest), this._reject.bind(originalRequest));
        }, RETRY_DELAY_IN_MS);
    }

    _copyRequestForRetry() {
        const retryRequest = new this.constructor(this._url, this._requestData, this._method);
        retryRequest._markRetry();

        return retryRequest;
    }

    _markRetry() {
        this._isRetry = true;
    }

    _redirectToLogin() {
        // Reload current page to trigger server-side redirect to /login with redirectUrl to current page
        setTimeout(() => window.location.reload(), 0);
    }

    _getResponseProperties(xmlHttpRequest) {
        const { status, responseText } = xmlHttpRequest;
        const contentType = xmlHttpRequest.getResponseHeader("Content-Type");
        const forbiddenReason = xmlHttpRequest.getResponseHeader("X-Forbidden-Reason");

        return { status, responseText, contentType, forbiddenReason };
    }

    _objectHasValue(object) {
        return object !== undefined && object !== null && Object.keys(object).length > 0;
    }
}

export const get = (url, data = null, retry = true) => new Request(url, data, "GET", retry).send();
export const post = (url, data = null, retry = true) => new Request(url, data, "POST", retry).send();
export const put = (url, data = null, retry = true) => new Request(url, data, "PUT", retry).send();
export const deleteXhr = (url, data = null, retry = true) => new Request(url, data, "DELETE", retry).send();
